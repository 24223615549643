import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

import metadata from './PMDriverDetailsComponent.metadata.json5';

function PMDriverDetailsComponent(props) {
    const {
        data: driver,
        onValueChange: writeValue,
        path: dataPath,
        id,
        index,
        showOptional,
        labelPosition,
        onValidate,
        phoneWide,
        handleLicenseTypeChange,
        driverCanHave10YearsExp,
        hasFullLicence,
        minAllowedToDriveDate,
        handleFirstdroveValueChange,
        handlePassedTestSinceValueChange,
        provisonalLicenseMonthYear,
        passedTestMonthYear,
        submitted
    } = props;
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];
    const translator = useTranslator();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation('PMDriverDetailsComponent');
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);
    const isPolicyHolder = (driver.isPolicyHolder === undefined || driver.isPolicyHolder.value === true)

    const getBooleanFieldValue = (fieldPath) => {
        const booleanVal = _.get(driver, fieldPath, false);

        return booleanVal;
    };
    const handleOnValueChange = (value, path) => {
        const fullPath = `${dataPath}.${path}`;
        writeValue(value, fullPath);
    };

    const onLicenseTypeChange = (value, path) => {
        const fullPath = `${dataPath}.${path}`;
        handleLicenseTypeChange(value, fullPath);
    };

    const firstDroveProvisionalLicenceFieldValidation = () => {
        const blnEnableFirstDroveProvisionalValidation = false;
        const strCurrentSelectedLicenceType = _.get(driver, 'licenseType.value.code');

        if (strCurrentSelectedLicenceType !== undefined
            && strCurrentSelectedLicenceType !== null
            && blnEnableFirstDroveProvisionalValidation) {
            const blnHasFullLicence = hasFullLicence(strCurrentSelectedLicenceType);

            return !blnHasFullLicence;
        }

        return false;
    };

    const firstDroveProvisionalLicenceMaxDate = () => {
        let objMaxDateReturn = {};

        if (passedTestMonthYear !== undefined) {
            objMaxDateReturn = passedTestMonthYear;
            objMaxDateReturn.day = 1;
        } else {
            objMaxDateReturn = LocalDateUtil.today();
        }

        return objMaxDateReturn;
    };

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'PMDriverDetailsComponent');
        }
    }, [id, onValidate, isComponentValid]);

    const overrideProps = {
        '@field': {
            phoneWide,
        },
        [`drivinglicencetype${index}`]: {
            onValueChange: onLicenseTypeChange,
            required: getBooleanFieldValue('licenseType.aspects.required'),
            showRequired: getBooleanFieldValue('licenseType.aspects.required'),
            availableValues: !_.isEmpty(driver) ? driver.licenseType.aspects.availableValues[0].typelist.getFilter('TelematicsLicenceType').codes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name
                })
            })) : []
        },
        [`passedTest10Years${index}`]: {
            required: getBooleanFieldValue('testPassed10Years.aspects.required'),
            showRequired: getBooleanFieldValue('testPassed10Years.aspects.required'),
            availableValues: YESNOFieldavailableValues,
            validationMessages: (_.get(driver, 'testPassed10Years.value') == null && submitted) ? ['This field is required'] : [],
            visible: driverCanHave10YearsExp && driver && hasFullLicence(_.get(driver, 'licenseType.value.code'))
        },
        [`testPassedSince${index}`]: {
            minDate: minAllowedToDriveDate,
            maxDate: LocalDateUtil.today(),
            required: (!_.get(driver, 'testPassed10Years.value')),
            showRequired: (!_.get(driver, 'testPassed10Years.value')),
            visible: _.get(driver, 'testPassed10Years.value') === false && driver && hasFullLicence(_.get(driver, 'licenseType.value.code')),
            value: hasFullLicence(_.get(driver, 'licenseType.value.code')) ? passedTestMonthYear : undefined,
            validationMessages: (passedTestMonthYear == '' || passedTestMonthYear == null) ? ['This field is required'] : [],
            onValueChange: handlePassedTestSinceValueChange
        },
        [`firstDroveProvisionalLicence${index}`]: {
            minDate: minAllowedToDriveDate,
            maxDate: firstDroveProvisionalLicenceMaxDate(),
            validationMessages: (provisonalLicenseMonthYear === undefined && submitted && firstDroveProvisionalLicenceFieldValidation()) ? ['This field is required'] : [],
            visible: _.get(driver, 'testPassed10Years.value') !== true,
            value: _.get(driver, 'testPassed10Years.value') ? undefined : provisonalLicenseMonthYear,
            onValueChange: handleFirstdroveValueChange,
            showRequired: firstDroveProvisionalLicenceFieldValidation(),
            required: firstDroveProvisionalLicenceFieldValidation()
        },
        [`otherVehiclesAccess${index}`]: {
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('accessToOtherVehicles.aspects.required'),
            showRequired: getBooleanFieldValue('accessToOtherVehicles.aspects.required'),
            validationMessages: (_.get(driver, 'accessToOtherVehicles.value') === undefined && submitted) ? ['This field is required'] : [],
            label: {
                id: "platform.inputs.contact-details.othervehiclesaccess",
                defaultMessage: isPolicyHolder ?  "Do you have access to any other vehicles?" : "Do they have access to any other vehicles?"
            },
        },
        [`nonMotoringOffences${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: (_.get(driver, 'nonMotoringConvictions.value') === undefined && submitted) ? ['This field is required'] : [],
            required: getBooleanFieldValue('nonMotoringConvictions.aspects.required'),
            showRequired: getBooleanFieldValue('nonMotoringConvictions.aspects.required'),
        },
        [`insurancePolicyCancelled${index}`]: {
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('cancelledPreviousInsurance.aspects.required'),
            showRequired: getBooleanFieldValue('cancelledPreviousInsurance.aspects.required'),
            validationMessages: (_.get(driver, 'cancelledPreviousInsurance.value') === undefined && submitted) ? ['This field is required'] : [],
            label: {
                id: "platform.inputs.contact-details.insurancepolicycancelled",
                defaultMessage: isPolicyHolder ? "Have you or anyone who will drive the car had an insurance policy cancelled at the request of any motor insurer?" : "Have they or anyone who will drive the car had an insurance policy cancelled at the request of any motor insurer?"
            },
        },
        [`additionalqualifications${index}`]: {
            label: {
                id: "translation.additionalqualifications.label",
                defaultMessage: isPolicyHolder ? "Do you have any additional driving qualifications?" : "Do they have any additional driving qualifications?"
            },
        },
        [`medicalconditions${index}`]: {
            label: {
                id: "translation.medicalconditions.label",
                defaultMessage: isPolicyHolder ? "Do you have any medical conditions affecting your driving?" : "Do they have any medical conditions affecting your driving?"
            },
        },

    };

    const resolvers = {
    };
    return (
        <ViewModelForm
            model={driver}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={handleOnValueChange}
            showErrors={submitted}
        />
    );
}
PMDriverDetailsComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMDriverDetailsComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: false,
    id: undefined,
    index: 0
};
export default PMDriverDetailsComponent;
