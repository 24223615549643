import React, { useCallback } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import metadata from './SessionTimeout.metadata.json5';
import styles from './SessionTimeout.module.scss';
import './SessionTimeout.messages';

function SessionTimeout(props) {
    const { location, history } = props;

    const overrideProps = {
    };

    const onNewQuoteClick = useCallback(() => {
        history.push("/");
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onNewQuoteClick: onNewQuoteClick
        }
    };

    return (
        <div className={styles.SessionTimeoutContainer}>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        </div>
    );
}

SessionTimeout.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            error: PropTypes.shape({
                gwInfo: PropTypes.shape({})
            })
        })
    })
};
SessionTimeout.defaultProps = {
    location: undefined
};
export default withRouter(SessionTimeout);
