import { defineMessages } from 'react-intl';

export default defineMessages({
    SessionTimeout: {
        id: 'quoteandbind.views.session-timeout.Purchased Failed',
        defaultMessage: 'Purchased Failed',
    },
    SessionTimeoutWarning: {
        id: 'quoteandbind.views.session-timeout.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    SessionTimeoutSolution: {
        id: 'quoteandbind.views.session-timeout.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.session-timeout.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.session-timeout.Reference Number',
        defaultMessage: 'Reference Number',
    },
});
