/* eslint-disable quote-props */
import _ from 'lodash';

const errorCodeRedirectURLMap = {
    601: '/declined',
    602: '/declined-uw',
    604: '/session-ended',
    607: '/session-timeout',
    608: '/declined',
    611: '/declined',
    624: '/declined-cue',
    630: '/error-missing-data',
    631: '/declined-uw',
    'default': ''
};

export default {
    getRedirectURL(errorObj) {
        if(!errorObj.appErrorCode) {
            return '';
        }
        return errorCodeRedirectURLMap[errorObj.appErrorCode] || errorCodeRedirectURLMap.default;
    }
};
