import { defineMessages } from 'react-intl';

export default defineMessages({
    DeclinedUW: {
        id: 'quoteandbind.views.declined-uw.Purchased Failed',
        defaultMessage: 'Purchased Failed',
    },
    DeclinedUWWarning: {
        id: 'quoteandbind.views.declined-uw.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    DeclinedUWSolution: {
        id: 'quoteandbind.views.declined-uw.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.declined-uw.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.declined-uw.Reference Number',
        defaultMessage: 'Reference Number',
    },
});
