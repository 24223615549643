import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelectField, Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import styles from './PMQuotePage_Excess.module.scss';
import messages from './PMQuotePage_excess.messages';

let excessAvailableValues = [];
let glassRepairExcess = 0;
let glassReplacementExcess = 0;

const setExcessValuesList = (values) => {
    if (excessAvailableValues.length === 0) {
        values.forEach((value) => {
            excessAvailableValues.push({ code: value, name: `£${value}` });
        });
    }
};

const setGlassExcessValues = (coverages) => {
    const glassCov = coverages.find((cov) => cov.codeIdentifier === 'PMGlassCov');
    if (glassCov) {
        const repairExcess = glassCov.terms.find((term) => term.patternCode === 'PMGlassRepairExcess');
        const replacementExcess = glassCov.terms.find((term) => term.patternCode === 'PMGlassReplacementExcess');
        if (repairExcess) {
            glassRepairExcess = repairExcess.chosenTermValue;
        }
        if (replacementExcess) {
            glassReplacementExcess = replacementExcess.chosenTermValue;
        }
    }
};

function PMQuotePage_ExcessSection(props) {
    const translator = useTranslator();
    const drivers = props.data.lobData.personalMotor.value.coverables.pmDrivers;
    const driverIndex = drivers.length> 0 ? drivers.findIndex((driver) => driver.isPolicyHolder === true) : 0;
    const personalMotor = props.data.lobData.personalMotor;
    const vehicle = personalMotor.value.coverables.pmVehicles[0];
    const driver = personalMotor.value.coverables.pmDrivers[driverIndex];
    setExcessValuesList(vehicle.voluntaryExcessOptions);
    setGlassExcessValues(personalMotor.offerings.value[0].coverages.pmVehicleCoverages[0].coverages);
    const [voluntaryExcess, setVoluntaryExcess] = useState(vehicle.voluntaryExcess);
    const [isDropdownActive, setIsDropdownActive] = useState(true);

    const updateQuote = useCallback((value) => {
        setIsDropdownActive(false);
        const parsedValue = parseInt(value);
        setVoluntaryExcess(parsedValue);
        props.onHandleSelectedVoluntaryBln(true);

        // eslint-disable-next-line react/destructuring-assignment
        if (props.onUpdateQuote) {
            // eslint-disable-next-line react/destructuring-assignment
            props.onUpdateQuote('quoteData.offeredQuotes[0]', parsedValue).then((response) => {
                setIsDropdownActive(true);
                // eslint-disable-next-line max-len
                setVoluntaryExcess(response.lobData.personalMotor.coverables.pmVehicles[0].voluntaryExcess);
            });
        }
    }, [props]);

    useEffect(() => {
        setVoluntaryExcess(parseInt(vehicle.voluntaryExcess));
        setExcessValuesList(vehicle.voluntaryExcessOptions);
        // eslint-disable-next-line max-len
        setGlassExcessValues(personalMotor.offerings.value[0].coverages.pmVehicleCoverages[0].coverages);
    }, [vehicle.voluntaryExcessOptions, vehicle.voluntaryExcess, personalMotor.offerings.value]);

    const [showCompulsoryDescription, setShowCompulsoryDescription] = useState(false);
    const toggleExcessDescription = () => {
        setShowCompulsoryDescription(!showCompulsoryDescription);
    };
    return (
        <div id="excessSectionContainer">
            <div id="yourExcessHeader" className={styles.andQuotePageSectionTitle}>
                <h2>{messages.excessTitle.defaultMessage}</h2>
            </div>
            <p>{messages.voluntaryExcessDescription.defaultMessage}</p>
            <br />
            <div>
                <h4>{messages.voluntaryExcess.defaultMessage}</h4>
                <DropdownSelectField
                    id="excessListDropdown"
                    availableValues={excessAvailableValues}
                    onValueChange={updateQuote}
                    value={voluntaryExcess}
                    placeholder="please select"
                    disabled={!isDropdownActive}
                />
            </div>
            <br />
            <p id="voluntaryExcessDescription2">{messages.voluntaryExcessDescription2.defaultMessage}</p>
            <div>
                <table className={styles.andExcessTable}>
                    <thead>
                        <tr>
                            <th>{messages.driver.defaultMessage}</th>
                            <th>
                                <span>{messages.compulsory.defaultMessage}</span>
                                <Icon
                                    icon="gw-info"
                                    className={styles.andInfoIcon}
                                    id="id-gw-info"
                                    size="medium"
                                    onClick={toggleExcessDescription}
                                />
                            </th>
                            <th>
                                <span>{messages.voluntary.defaultMessage}</span>
                            </th>
                            <th>{messages.total.defaultMessage}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ display: showCompulsoryDescription ? 'table-row' : 'none' }}>
                            <td colSpan="4">{messages.compulsoryTooltipMessage.defaultMessage}</td>
                        </tr>
                        <tr>
                            <td>{translator(messages.driverName.defaultMessage, { firstName: driver.firstName, lastName: driver.lastName })}</td>
                            <td>&#163;{driver.compulsoryExcess}</td>
                            <td>&#163;{voluntaryExcess}</td>
                            <td>&#163;{(driver.compulsoryExcess + voluntaryExcess)}</td>
                        </tr>
                        {drivers.map(( driver, index ) => {
                                return (
                                    index !== driverIndex ?
                                        <tr key={index}>
                                            <td>{translator(messages.driverName.defaultMessage, { firstName: driver.firstName, lastName: driver.lastName })}</td>
                                            <td>&#163;{driver.compulsoryExcess}</td>
                                            <td>&#163;{voluntaryExcess}</td>
                                            <td>&#163;{(driver.compulsoryExcess + voluntaryExcess)}</td>
                                        </tr>
                                    : <tr></tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div>
                <table className={styles.andExcessTable}>
                    <thead>
                        <tr>
                            <th>{messages.otherCompulsoryExcesses.defaultMessage}</th>
                            <th>{messages.total.defaultMessage}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{messages.windscreenReplacement.defaultMessage}</td>
                            <td>{glassReplacementExcess}</td>
                        </tr>
                        <tr>
                            <td>{messages.windscreenRepair.defaultMessage}</td>
                            <td>{glassRepairExcess}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>{messages.voluntaryExcessWarning.defaultMessage}</p>
        </div>
    );
}

PMQuotePage_ExcessSection.propTypes = {
    data: PropTypes.shape({}),
    volExcess: PropTypes.number,
    onUpdateQuote: PropTypes.func
};

export default PMQuotePage_ExcessSection;
