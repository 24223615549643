import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleLicensePlate: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.License Plate',
        defaultMessage: 'License Plate',
    },
    paVehicleLicenseState: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.State',
        defaultMessage: 'State',
    },
    paVehicleCostNew: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.Cost New',
        defaultMessage: 'Cost New',
    },
});
