import moment from 'moment';

  function importAll(r) {
    return r.keys().map(r);
  }
  
  const fileNames = importAll(
    require.context("../../../../docs/tya/", false, /\.(pdf)$/)
  );

  const DOC_STANDARD_NAME = {
    CORE_IPID: 'insurance_product_information_document',
    CCCP_IPID: 'cccplus_insurance_product_information_document',
    CCCP_POLICY: 'cccplus_policy_document',
    KEYS_IPID: 'keys_insurance_product_information_document',
    KEYS_POLICY: 'keys_policy_document',
    BREAKDOWN_IPID_BASIC: 'breakdown_basic_insurance_product_information_document',
    BREAKDOWN_IPID_EURO: 'breakdown_euro_insurance_product_information_document',
    BREAKDOWN_IPID_LVL2: 'breakdown_finest_insurance_product_information_document',
    BREAKDOWN_POLICY: 'breakdown_policy_document',
    PRIVACY_POLICY: 'privacy_policy',
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
    IMPORTANT_INFORMATION: 'important_information',
    PRIVATE_CAR_POLICY_DOCUMENT: 'private_car_policy_document',
    PRIVACY_NOTICE: 'privacy_notice',
    COOKIE_POLICY: 'cookie_policy',
    LEGAL_EXP_IPID: 'legal_expenses_insurance_product_information_document',
    LEGAL_EXP_POLICY: 'legal_expenses_policy_document',
    ECCC_IPID: 'eccc_insurance_product_information_document',
    ECCC_POLICY: 'eccc_policy_document',
    MOTOR_EXCESS_IPID: 'motor_excess_protect_insurance_product_information_document',
    MOTOR_EXCESS_POLICY: 'motor_excess_protect_policy_document',
    PERSONAL_ACCIDENT_IPID: 'personal_accident_insurance_product_information_document',
    PERSONAL_ACCIDENT_POLICY: 'personal_accident_policy_document',
    TOOLS_IN_TRANSIT_IPID: 'tools_in_transit_insurance_product_information_document',
    TOOLS_IN_TRANSIT_POLICY: 'tools_in_transit_policy_document',
    LEXUS_TERMS: 'lexusrz_terms_and_conditions',
    PNCD_APPENDIX: 'pncd_appendix'
};

export default {
    getFileUrl(brand, docKey) {
        const docNamePrefix = DOC_STANDARD_NAME[docKey];
        const docUrlStartString = `/common/${docNamePrefix}-`;

        const currentDate = new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).split('/').join('-');
        
        const filteredFiles = fileNames.filter(function (str) { return str.includes(docUrlStartString); });
        
        const datesExtractedFromFileNames = filteredFiles.map((filePath) => {
          const dateMatch = filePath.match(/(\d{2}-\d{2}-\d{4})/);
      
          if (dateMatch && dateMatch[1]) {
            return dateMatch[1];
          }
          return null;
        });
        
        const filteredDatesExtractedFromFileNames = datesExtractedFromFileNames.filter(item => moment(item,'DD-MM-YYYY').isSameOrBefore(moment(currentDate,'DD-MM-YYYY')));

        const latestDateEffectiveFromFiles = filteredDatesExtractedFromFileNames.sort((a, b) => {
          const dateA = new Date(b.split('-').reverse().join('-'));
          const dateB = new Date(a.split('-').reverse().join('-'));
          return dateA - dateB;
        })[0];

        const currentFileVersion = filteredFiles.filter(function (str) { return str.includes(latestDateEffectiveFromFiles); });

        const currentFileVersionPath = currentFileVersion[0].replace("../","/");

        const href = window.location.href;
        var pathArray = href.split( '/' );
        var protocol = pathArray[0];
        var host = pathArray[2];
        var baseurl = protocol + '//' + host;
        const url = `${baseurl}${currentFileVersionPath}`;
      
        return url;
      }
};