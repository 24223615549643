import { defineMessages } from 'react-intl';

export default defineMessages({
    pmLegalTwoOfThreeTitle: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Title',
        defaultMessage: 'Legal and payment (step 2 of 3)',
    },
    pmLegalTwoOfThreeAutomaticRenewal: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Automatic Renewal',
        defaultMessage: 'Automatic Renewal',
    },
    pmLegalTwoOfThreeAutomaticRenewalSubText: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Automatic Renewal Sub Text',
        defaultMessage: 'Keeping your car covered year after year with Toyota Insurance Services.',
    },
    pmLegalTwoOfThreeAutomaticRenewalParagraph: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Automatic Renewal Paragraph',
        defaultMessage: 'This policy automatically renews every year, you can update your renewal preferences, either by contacting us, or updating your preferences in Your Portal. This means that at renewal if you wish to continue your insurance with us, you do not need to do anything.',
    },
    pmCardDetails: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Card Details',
        defaultMessage: 'Card details',
    },
    pmHappyToSaveCardDetails: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Happy to save card details',
        defaultMessage: "By selecting No, your card details won't be saved for any future payments. We will contact you to request card details ahead of your policy renewal.",
    },
    pmThePaymentReference: {
        id: 'quoteandbind.pm.views.pm-legal2-information.The Payment reference',
        defaultMessage: 'Please note - the payment reference shown on your bank statement will be PC/AIOI INSURANCE.',
    },
    pmStoreCardForFutureITB: {
        id: 'quoteandbind.pm.views.pm-legal2-information.storeCardForFuture_itb',
        defaultMessage: 'Are you happy for us to store your card details for future policy changes?',
    },
    pmBillingPostalCode: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Billing postcode',
        defaultMessage: 'Billing postcode',
    },
    pmBillingCountry: {
        id: 'quoteandbind.pm.views.pm-legal2-information.Billing county',
        defaultMessage: 'Billing county',
    },
    pmStoreDetailsToolTip: {
        id: 'quoteandbind.pm.views.pm-legal2-information.HappyCardDetails.tooltip.content',
        defaultMessage: 'This means we’ll save your card to use for any future policy changes. By selecting No, your card details won’t be saved for any future payments.',
    },
    pmNameAsAppearsOnCard: {
        id: 'quoteandbind.pm.views.pm-legal2-information.HappyCardDetails.Name on card',
        defaultMessage: 'Name as it appears on card',
    },
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-legal2-information-error-one-of-two',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    }
});
