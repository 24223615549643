/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import _, { set } from 'lodash';
import { withRouter } from 'react-router-dom';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ScrollToError } from '@jutro/wizard-next';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { MockUpUtil } from 'gw-portals-util-js';
import moment from 'moment';
import { useTranslator } from '@jutro/locale';
import { MissingDataError } from 'react-intl';
import config from 'app-config';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import metadata from './DriverQualificationPage.metadata.json5';
import styles from './DriverQualificationPage.module.scss';
import messagesTranslationsSet from './DriverQualificationPage.messages';
import ValidationUtil from '../../util/ValidationUtil';

const PATH_TO_MOCK_DATA = 'quote.pm';
const MOCK_DATA_TO_SET = ['baseData.accountHolder.emailAddress1'];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];
function getAgeAt(dateDTO, driver) {
    if (!driver.dateOfBirth) {
        return 0;
    }
    const date = moment([dateDTO.year, dateDTO.month, dateDTO.day]);
    const dob = moment([driver.dateOfBirth.year, driver.dateOfBirth.month, driver.dateOfBirth.day]);
    return date.diff(dob, 'years');
}
function hasFullLicence(licenseType) {
    return ['FullEU', 'FullUK', 'InternationalLicence', 'FullChannelIslands', 'FullIoM', 'Japanese', 'Other'].includes(licenseType);
}

function DriverQualificationPage(props) {
    const { wizardData: submissionVM, updateWizardData, location, history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { onValidate, isComponentValid, initialValidation, disregardFieldValidation } = useValidation('DriverQualificationPage');
    const drvrs = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.value', []);
    const policyHolderIndex = drvrs.length > 0 ? drvrs.findIndex((drvr) => drvr.isPolicyHolder === true) : 0;
    const policyHolderVM = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}]`);
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const [errorResponse, setErrorResponse] = useState({});
    const driversPath = 'lobData.personalMotor.coverables.pmDrivers';
    const monthList = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].provisionalLicenseMonth.aspects.availableValues`);
    const defaultDate = [{
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    }];
    const [submitted, setSubmitted] = useState(false);
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];
    const translator = useTranslator();
    const { ageAllowedToDrive } = config.personalAutoConfig;
    const [minAllowedToDriveDate, setMinAllowedToDriveDate] = useState(null);
    const [driverCanHave10YearsExp, setDriverCanHave10YearsExp] = useState(false);
    const [isPolicyHolder, setIsPolicyHolder] = useState();
    const [isRefresh, setRefresh] = useState(false);
    const ageRefDateDTO = submissionVM.baseData.periodStartDate.value;
    const disableNextBool = !(isComponentValid);
    const isHomeNumberValid = ValidationUtil.isHomeNumberValid(submissionVM);
    const isPhoneNumberValid = ValidationUtil.isPhoneNumberValid(submissionVM);
    const isPhoneNumberLnPValid = ValidationUtil.isPhoneNumberLnPValid(submissionVM);
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'DriverQualificationPage', {}, isPhoneNumberLnPValid);

    const createDriverVM = useCallback(() => {
        if (_.isEmpty(submissionVM.lobData.personalMotor.coverables.pmDrivers.value)) {
            const accountHolder = submissionVM.baseData.accountHolder.value;
            let driverObj = accountHolder;
            driverObj.mainDriver = true;
            driverObj.isPolicyHolder = true;
            const { _dtoName, _xCenter } = submissionVM.lobData.personalMotor.coverables.pmDrivers;
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
            submissionVM.lobData.personalMotor.coverables.pmDrivers.pushElement(driverVM);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    useEffect(() => {
        const drivers = _.get(submissionVM, `${driversPath}.value`);

        if (typeof submissionVM.bindData.autoRenew_itb !== 'undefined' && submissionVM.bindData.autoRenew_itb && Object.keys(submissionVM.bindData.autoRenew_itb).length > 0) {
            if (submissionVM.bindData.autoRenew_itb.value === undefined) submissionVM.bindData.autoRenew_itb.value = true;
        }

        if (_.isEmpty(drivers)) {
            createDriverVM();
        }
        const vm = viewModelService.clone(submissionVM);
        if (!_.isEqual(vm, submissionVM)) {
            if (!_.isEmpty(policyHolderVM && policyHolderVM.licenseType)) {
                const { baseData: { accountHolder } } = vm.value;
                const { mainDriver: accountHolderMainDriver, ...restOfAccountHolder } = accountHolder;
                const policyHolder = _.get(vm, `value.lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}]`);
                _.set(vm, `value.lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}]`, {
                    ...policyHolder,
                    ...restOfAccountHolder
                });
            }
            updateWizardData(vm);
        }
        const driver = _.get(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}]`);
        if (!_.isEmpty(driver) && driver.dateOfBirth) {
            // Driving test pass and drove on provisional licence minimum date
            const newMinAllowedToDriveDate = moment([
                driver.dateOfBirth.year + ageAllowedToDrive,
                driver.dateOfBirth.month,
                driver.dateOfBirth.day
            ]);
            let driverCanHave10YearsExp = getAgeAt(ageRefDateDTO, driver) >= ageAllowedToDrive + 10;
            const minDate = {
                day: newMinAllowedToDriveDate.date(),
                month: newMinAllowedToDriveDate.month(),
                year: newMinAllowedToDriveDate.year()
            };
            setIsPolicyHolder(driver.isPolicyHolder === true);
            setMinAllowedToDriveDate(minDate);
            setDriverCanHave10YearsExp(
                driverCanHave10YearsExp
            );
            if (!driverCanHave10YearsExp) {
                driver.testPassed10Years = false;
            }
        }
        
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(1));
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            if (path === `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].licenseType`) {
                if (value && !hasFullLicence(newSubmissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].value)) {
                    if (!driverCanHave10YearsExp) {
                        newSubmissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].testPassed10Years.value = false;
                    } else {
                        newSubmissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].testPassed10Years.value = undefined;
                    }
                }
            }
            updateWizardData(newSubmissionVM);
        },
        [driverCanHave10YearsExp, policyHolderIndex, submissionVM, updateWizardData, viewModelService]
    );

    const onNext = useCallback(async () => {
        if (invalidPropsLength > 0) {
            setErrorTimestamp(Date.now());
            setSubmitted(true);
            return false;
        }

        try {
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
            const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newUpdatedSubmissionVM);
            return newUpdatedSubmissionVM;
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            history.push({
                pathname: '/error',
                data: error,
                origin: "DriverQualificationPage [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [history, invalidPropsLength, submissionVM, updateWizardData, viewModelService]);

    const addConviction = useCallback(() => {
        const convictionObj = {
            convictionCode: undefined,
            convictionDate: undefined,
            convictionPoints: undefined,
            disqualificationMonths: undefined,
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmMotorConvictions;
        const convictionVM = viewModelService.create(convictionObj, _xCenter, _dtoName);
        if (!submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmMotorConvictions.value) {
            submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmMotorConvictions.value = [];
        }
        submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmMotorConvictions.pushElement(convictionVM);
        updateWizardData(submissionVM);
    }, [policyHolderIndex, submissionVM, updateWizardData, viewModelService]);

    const addClaim = useCallback(() => {
        const claimObj = {
            claimDate: undefined,
            claimType: undefined,
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmClaims;
        const claimVM = viewModelService.create(claimObj, _xCenter, _dtoName);
        if (!submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmClaims.value) {
            submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmClaims.value = [];
        }
        submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].pmClaims.pushElement(claimVM);
        updateWizardData(submissionVM);
    }, [policyHolderIndex, submissionVM, updateWizardData, viewModelService]);

    const removeConviction = useCallback(
        (index) => {
            const convictionListPath = `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.value`;
            const convictionList = _.get(submissionVM, convictionListPath);
            convictionList.splice(index, 1);
            if (_.get(submissionVM, convictionListPath).length <= 0) {
                _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorConvictions.value`, false);
            }
            disregardFieldValidation(`pmConvictionsComponent${index}`);
            updateWizardData(submissionVM);
        },
        [disregardFieldValidation, policyHolderIndex, submissionVM, updateWizardData]
    );

    const removeClaim = useCallback(
        (index) => {
            const claimListPath = `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.value`;
            const claimList = _.get(submissionVM, claimListPath);
            claimList.splice(index, 1);
            if (_.get(submissionVM, claimListPath).length <= 0) {
                _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorClaims.value`, false);
            }
            disregardFieldValidation(`pmClaimsComponent${index}`);
            updateWizardData(submissionVM);
        },
        [disregardFieldValidation, policyHolderIndex, submissionVM, updateWizardData]
    );

    const getMonthYearFieldValue = (monthDtoPath, yearDtoPath) => {
        const dtoMonth = _.get(submissionVM, monthDtoPath);
        const dtoYear = _.get(submissionVM, yearDtoPath);
        if (dtoMonth === undefined || dtoYear === undefined) {
            return undefined;
        // eslint-disable-next-line no-else-return
        } else {
            let monthNum = 0;
            monthList.forEach((month, index) => {
                if (month.code === dtoMonth.code) {
                    monthNum = index;
                }
            });
            return { month: monthNum, year: dtoYear };
        }
    };

    const [provisonalLicenseMonthYear, setProvisonalLicenseMonthYear] = useState(getMonthYearFieldValue(
        `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].provisionalLicenseMonth.value`,
        `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].provisionalLicenseYear.value`
    ));

    const [passedTestMonthYear, setPassedTestMonthYear] = useState(getMonthYearFieldValue(
        `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].passedTestMonth.value`,
        `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].passedTestYear.value`
    ));

    const getBooleanFieldValue = (path) => {
        const booleanVal = _.get(submissionVM, path, false);
        return booleanVal;
    };

    const handleLicenseTypeChange = useCallback((value, path) => {
        _.set(submissionVM, path, value);
        if (value && !hasFullLicence(submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].value)) {
            if (!driverCanHave10YearsExp) {
                submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].testPassed10Years.value = false;
            } else {
                submissionVM.lobData.personalMotor.coverables.pmDrivers.children[policyHolderIndex].testPassed10Years.value = undefined;
            }
        }
        updateWizardData(submissionVM);
        setRefresh(!isRefresh);
    }, [submissionVM, policyHolderIndex, updateWizardData, isRefresh, driverCanHave10YearsExp]);

    const isDateInTheAllowedMinMaxRange = (objCheckDate, objMinDate, objMaxDate) => {
        // eslint-disable-next-line max-len
        return objCheckDate.getTime() >= objMinDate.getTime() && objCheckDate.getTime() <= objMaxDate.getTime();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleCalendarDateValues = (value) => {
        if (value !== undefined) {
            let blnIsDateInRange = false;
            if (minAllowedToDriveDate !== undefined) {
                blnIsDateInRange = isDateInTheAllowedMinMaxRange(
                    new Date(value.year, value.month, 1),
                    // eslint-disable-next-line max-len
                    new Date(minAllowedToDriveDate.year, minAllowedToDriveDate.month, minAllowedToDriveDate.day),
                    // eslint-disable-next-line max-len
                    new Date(LocalDateUtil.today().year, LocalDateUtil.today().month, LocalDateUtil.today().day)
                );
            }

            return blnIsDateInRange;
        }
    };

    const handlePassedTestSinceValueChange = useCallback(
        (value) => {
            if (value !== undefined) {
                const provisionalLicenseYear = _.get(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseYear`);
                if (provisionalLicenseYear && provisionalLicenseYear > value.year) {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseMonth`, undefined);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseYear`, undefined);
                    setProvisonalLicenseMonthYear(undefined);
                }
                const selectedMonth = monthList[value.month].code;
                const blnDateInRange = handleCalendarDateValues(value);
                if (blnDateInRange) {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].passedTestMonth`, selectedMonth);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].passedTestYear`, value.year);
                    setPassedTestMonthYear({
                        month: value.month,
                        year: value.year
                    });
                } else {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].passedTestMonth`, undefined);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].passedTestYear`, undefined);
                    setPassedTestMonthYear(undefined);
                }
                updateWizardData(submissionVM);
            }
        },
        [submissionVM, policyHolderIndex, monthList, handleCalendarDateValues, updateWizardData]
    );

    const handleFirstdroveValueChange = useCallback(
        (value) => {
            if (value !== undefined) {
                const selectedMonth = monthList[value.month].code;
                const blnDateInRange = handleCalendarDateValues(value);
                if (blnDateInRange) {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseMonth`, selectedMonth);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseYear`, value.year);
                    setProvisonalLicenseMonthYear({
                        month: value.month,
                        year: value.year
                    });
                } else {
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseMonth`, undefined);
                    _.set(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].provisionalLicenseYear`, undefined);
                    setProvisonalLicenseMonthYear(undefined);
                }
                updateWizardData(submissionVM);
            }
        },
        [monthList, handleCalendarDateValues, updateWizardData, submissionVM, policyHolderIndex]
    );

    const handleMotorConvictionsValueChange = useCallback((value, path) => {
        _.set(submissionVM, `${path}.value`, value);
        if (value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.value`, []).length <= 0) {
            addConviction();
        } else if (!value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.value`, []).length > 0) {
            _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.value`, []).forEach((conviction, index) => {
                disregardFieldValidation(`pmConvictionsComponent${index}`);
            });
            _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.value`, []);
        }
        updateWizardData(submissionVM);
    }, [submissionVM, policyHolderIndex, updateWizardData, addConviction, disregardFieldValidation]);

    const handleMotorClaimsValueChange = useCallback((value, path) => {
        _.set(submissionVM, `${path}.value`, value);
        if (value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.value`, []).length <= 0) {
            addClaim();
        } else if (!value && _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.value`, []).length > 0) {
            _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.value`, []).forEach((claim, index) => {
                disregardFieldValidation(`pmClaimsComponent${index}`);
            });
            _.set(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.value`, []);
        }
        updateWizardData(submissionVM);
    }, [submissionVM, policyHolderIndex, updateWizardData, addClaim, disregardFieldValidation]);

    const generateOverrides = useCallback(() => {
        const convictions = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.value`, []);
        const claims = _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.value`, []);
        const overrideProps = {};

        convictions.forEach((conviction, index) => {
            overrideProps[`pmConvictionsComponent${index}`] = {
                submitted: submitted,
                convictionCodeValues: submissionVM.value.lobData.personalMotor.coverables.convictionCodes ? submissionVM.lobData.personalMotor.coverables.convictionCodes.value.map((typeCode) => ({
                    code: typeCode,
                    name: typeCode
                })) : []
            };
            overrideProps[`pmDeleteConviction${index}`] = {
                visible: true,
                onClick: () => {
                    removeConviction(index);
                }
            };
        });
        if (Array.isArray(claims)) {
            claims.forEach((claim, index) => {
                overrideProps[`pmClaimsComponent${index}`] = {
                    submitted: submitted,
                };
                overrideProps[`pmDeleteClaim${index}`] = {
                    visible: true,
                    onClick: () => {
                        removeClaim(index);
                    }

                };
            });
        }

        return overrideProps;
    }, [submissionVM, policyHolderIndex, submitted, removeConviction, removeClaim]);

    const overrideProps = {
        driverMotorConvictions: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleMotorConvictionsValueChange,
            path: `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorConvictions`,
            required: getBooleanFieldValue(`lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorConvictions.aspects.required`),
            showRequired: getBooleanFieldValue(`lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorConvictions.aspects.required`),
            label: {
                id: 'platform.inputs.contact-details.drivermotorconvictions',
                defaultMessage: isPolicyHolder ? 'Have you had any motoring convictions, disqualifications, driving licence endorsements or fixed penalties in the last 5 years?' : 'Have they had any motoring convictions, disqualifications, driving licence endorsements or fixed penalties in the last 5 years?'
            },
        },
        addConviction: {
            visible: _.get(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].hasMotorConvictions`, false),
            onClick: addConviction,
        },
        convictionContainer: {
            path: `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmMotorConvictions.children`,
        },
        claimContainer: {
            path: `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].pmClaims.children`,
        },
        inlineNotificationErrorBarDriversOneOfTwo: {
            message: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).strErrorBannerTextOutput,
            visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, submitted).blnErrorWidgetVisibility
        },
        driverMotorClaims: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleMotorClaimsValueChange,
            path: `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorClaims`,
            required: getBooleanFieldValue(`lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorClaims.aspects.required`),
            showRequired: getBooleanFieldValue(`lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}].hasMotorClaims.aspects.required`),
            label: {
                id: 'platform.inputs.contact-details.drivermotorclaims',
                defaultMessage: isPolicyHolder ? 'Have you had any motoring claims, accidents or losses in the last 5 years?' : 'Have they had any motoring claims, accidents or losses in the last 5 years?'
            },
        },
        addClaim: {
            visible: _.get(submissionVM.value, `lobData.personalMotor.coverables.pmDrivers[${policyHolderIndex}].hasMotorClaims`, false),
            onClick: addClaim,
        },
        maindriverDetails: {
            onValueChange: writeValue,
            path: `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}]`,
            data: _.get(submissionVM, `lobData.personalMotor.coverables.pmDrivers.children[${policyHolderIndex}]`),
            handleLicenseTypeChange: handleLicenseTypeChange,
            handlePassedTestSinceValueChange: handlePassedTestSinceValueChange,
            handleFirstdroveValueChange: handleFirstdroveValueChange,
            driverCanHave10YearsExp: driverCanHave10YearsExp,
            minAllowedToDriveDate: minAllowedToDriveDate,
            hasFullLicence: hasFullLicence,
            provisonalLicenseMonthYear: provisonalLicenseMonthYear,
            passedTestMonthYear: passedTestMonthYear,
            submitted: submitted
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue: writeValue,
            onRemoveConviction: removeConviction,
            onAddConvictionClick: addConviction,
            onAddClaimClick: addClaim,
            onRemoveClaim: removeClaim,
            onValidate: onValidate
        }
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "DriverQualificationPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                disableNext={false}
                skipWhen={initialValidation}
                nextLabel={'Continue'}
                previousLabel={'Back'}
                disableCancel={true}
                cancelLabel={''}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    showErrors={submitted}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

DriverQualificationPage.propTypes = wizardProps;
export default withRouter(DriverQualificationPage);
