import { defineMessages } from 'react-intl';

export default defineMessages({
    DeclinedCue: {
        id: 'quoteandbind.views.declined-cue.Declined Cue',
        defaultMessage: 'Declined Cue',
    },
    DeclinedCueWarning: {
        id: 'quoteandbind.views.declined-cue.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    DeclinedCueSolution: {
        id: 'quoteandbind.views.declined-cue.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.declined-cue.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.declined-cue.Reference Number',
        defaultMessage: 'Reference Number',
    },
});
