import React from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import metadata from './PurchaseFailed.metadata.json5';
import styles from './PurchaseFailed.module.scss';
import './PurchaseFailed.messages';

function PurchaseFailed(props) {
    const { location } = props;

    const overrideProps = {
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <div className={styles.PurchaseFailedContainer}>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        </div>
    );
}

PurchaseFailed.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            error: PropTypes.shape({
                gwInfo: PropTypes.shape({})
            })
        })
    })
};
PurchaseFailed.defaultProps = {
    location: undefined
};
export default withRouter(PurchaseFailed);
