import { defineMessages } from 'react-intl';

export default defineMessages({
    ErrorPage: {
        id: 'quoteandbind.views.tech-error.Tech Error',
        defaultMessage: 'Tech Error',
    },
    ErrorPageWarning: {
        id: 'quoteandbind.views.tech-error.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    ErrorPageSolution: {
        id: 'quoteandbind.views.tech-error.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.tech-error.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.tech-error.Reference Number',
        defaultMessage: 'Reference Number',
    },
});
