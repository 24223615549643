/* eslint-disable max-len */
import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PMVehicleComponent.metadata.json5';
import './PMVehicleComponent.messages';
import moment from 'moment';

function PMVehicleComponent(props) {
    const {
        data: vehicleVM,
        readOnlyFields,
        phoneWide,
        labelPosition,
        showOptional,
        path,
        id,
        index,
        onValidate,
        submitted,
        onValueChange,
        handleRegModeActive,
        submissionVM,
        updateWizardData
    } = props;
    const requireFieldError = ['This field is required'];
    const invalidDateError = ['Invalid Date'];
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [showPurchaseField, setShowPurchaseField] = useState(false);
    const [showInvalidDateMessage, setInvalidDateMessage] = useState(false);
    const [isDateHandlerTriggered, setDateHandlerTriggered] = useState(false);
    const [minRegisteredYear, setMinRegisteredYear] = useState(Date.parse(`${vehicleVM.firstRegisteredMonth.value || 1} 1, ${vehicleVM.firstRegisteredYear.value || 1900}`));
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const monthList = _.get(vehicleVM, 'purchasedMonth.aspects.availableValues');
    const translator = useTranslator();

    const getModificationsAvailableValues = () => {
        const noModifications = { code: 'no', name: 'No' };
        const updatedValues = [noModifications];
        vehicleVM.modification.aspects.availableValues.map((availableValue) => {
            updatedValues.push({
                code: availableValue.code,
                name: translator({
                    id: availableValue.name,
                    defaultMessage: availableValue.name
                })
            });
        });

        return updatedValues;
    };
    const getVehicleMod = () => {
        const values = getModificationsAvailableValues();
        if (vehicleVM.value.hasBeenModified === false) {
            return values.find((mod) => {
                return mod.code === 'no';
            });
        }
        return values.find((mod) => {
            return mod.code === vehicleVM.modification.code;
        });
    };
    const [getModificationChangeValue, setModificationChangeValue] = useState(getVehicleMod());

    const defaultDate = [{
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    }];
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const handleRegModeChanges = (value) => {
        handleRegModeActive(value);
    };

    const handleValueChange = useCallback(
        (value, changedPath) => {
            if (changedPath === 'vehicle.abicode' || changedPath === 'vehicle.firstRegisteredYear') {
                setShowPurchaseField(true);
            }
            if (changedPath === 'vehicle.marketValue.amount') {
                const newMarketValuePath = 'marketValue';
                const marketValueObj = {
                    amount: value,
                    currency: 'gbp'
                };
                _.set(vehicleVM.value, newMarketValuePath, marketValueObj);
            }
            const newPath = changedPath.replace(/^vehicle\./, '');
            const fullPath = `${path}.${newPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path, vehicleVM]
    );

    const buildJutroCompatibleDate = (objMonth, strYear) => {
        let monthNum = 0;
        if (objMonth !== undefined) {
            monthList.forEach((month, monthIndex) => {
                if (month.code !== undefined && objMonth.code !== undefined) {
                    if (month.code === objMonth.code) {
                        monthNum = monthIndex;
                    }
                }
            });
            return { month: monthNum, year: strYear };
        }
        return {};
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPurchasedDateRegistrationDateValidator = () => {
        let blnValidDateElement = true;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        let userEnteredDate = null;
        let vehicleLookupDate = null;
        const nowDate = moment(`${currentYear}-${currentMonth + 1}-01`);
        // Nested function to build a date object from the month and year fields
        const buildDate = (monthPath, yearPath) => {
            const month = _.get(vehicleVM, monthPath);
            const year = _.get(vehicleVM, yearPath);
            return (month !== undefined && year !== undefined) ? buildJutroCompatibleDate(month, year) : null;
        };
        const objUserEnteredPurchaseDate = buildDate('purchasedMonth.value', 'purchasedYear.value');
        const objVehicleLookupRegisteredDate = buildDate('firstRegisteredMonth.value', 'firstRegisteredYear.value');

        if (objVehicleLookupRegisteredDate && objUserEnteredPurchaseDate) {
            userEnteredDate = moment(`${objUserEnteredPurchaseDate.year}-${objUserEnteredPurchaseDate.month + 1}-01`);
            vehicleLookupDate = moment(`${objVehicleLookupRegisteredDate.year}-${objVehicleLookupRegisteredDate.month + 1}-01`);
            if (!userEnteredDate.isBetween(vehicleLookupDate, nowDate)) {
                blnValidDateElement = false;
                setInvalidDateMessage(true);
            } else {
                blnValidDateElement = true;
                setInvalidDateMessage(false);
            }
        }
        if (objUserEnteredPurchaseDate && !objVehicleLookupRegisteredDate) {
            userEnteredDate = moment(`${objUserEnteredPurchaseDate.year}-${objUserEnteredPurchaseDate.month + 1}-01`);
            if (userEnteredDate.isAfter(nowDate)) {
                blnValidDateElement = false;
                setInvalidDateMessage(true);
            } else {
                blnValidDateElement = true;
                setInvalidDateMessage(false);
            }
        }
        return blnValidDateElement;
    };

    const getMonthYearFieldValue = (monthDtoPath, yearDtoPath) => {
        const dtoMonth = _.get(vehicleVM, monthDtoPath);
        const dtoYear = _.get(vehicleVM, yearDtoPath);
        if (dtoMonth === undefined || dtoYear === undefined) {
            return undefined;
        // eslint-disable-next-line no-else-return
        } else {
            let monthNum = 0;
            monthList.forEach((month, index) => {
                if (month.code === dtoMonth.code) {
                    monthNum = index;
                }
            });
            return { month: monthNum, year: dtoYear };
        }
    };
    const [purchasedCarMonthYear, setPurchasedCarMonthYear] = useState(getMonthYearFieldValue(
        'purchasedMonth.value',
        'purchasedYear.value'
    ));

    const getBooleanFieldValue = (path) => {
        const booleanVal = _.get(vehicleVM, path, false);

        return booleanVal;
    };

    const purchasedDateValidationsMessage = () => {
        let arrMessage = [];
        if (showInvalidDateMessage) {
            arrMessage = invalidDateError;
        } else if (!purchasedCarMonthYear) {
            arrMessage = requireFieldError;
        }
        return arrMessage;
    };

    const handleModificationValueChange = useCallback(
        (value, path) => {
            setModificationChangeValue(value);
            let selectVehicleMod = null;
            vehicleVM.modification.aspects.availableValues.forEach((mods) => {
                if (mods.code === value) {
                    selectVehicleMod = mods;
                }
            });
            _.set(vehicleVM, 'generalAfterMarketMods.value', ['']);
            _.set(vehicleVM, 'bodyWorkMods.value', ['']);
            _.set(vehicleVM, 'engineMods.value', ['']);
            _.set(vehicleVM, 'wheelsOrTyresMods.value', ['']);
            _.set(vehicleVM, 'conversionMods.value', ['']);
            _.set(vehicleVM, 'otherMods.value', ['']);
            const _Path = 'modification';
            if (value === 'no') {
                _.set(vehicleVM, _Path, null);
                _.set(vehicleVM.value, 'hasBeenModified', false);
            } else {
                _.set(vehicleVM, _Path, selectVehicleMod);
                _.set(vehicleVM.value, 'hasBeenModified', true);
            }
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, vehicleVM]
    );

    useEffect(() => {
        setMinRegisteredYear(Date.parse(`${vehicleVM.firstRegisteredMonth.value?.code || 1} 1, ${vehicleVM.firstRegisteredYear.value || 1900}`));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const carRegisteredDate = new Date(minRegisteredYear);

    const handleDatePurchasedValueChange = useCallback(
        (value) => {
            if (value !== undefined) {
                const currentYear = new Date().getFullYear();
                if (value.year && value.year > currentYear) {
                    _.set(vehicleVM.value, 'purchasedMonth', undefined);
                    _.set(vehicleVM.value, 'purchasedYear', undefined);
                    setInvalidDateMessage(true);
                    setPurchasedCarMonthYear(undefined);
                    return;
                }
                getPurchasedDateRegistrationDateValidator();
                const selectedMonth = monthList[value.month].code;
                _.set(vehicleVM.value, 'purchasedMonth', selectedMonth);
                _.set(vehicleVM.value, 'purchasedYear', value.year);
                setPurchasedCarMonthYear({
                    month: value.month,
                    year: value.year
                });

                let isValidDate = false;
                if (value.year >= carRegisteredDate.getFullYear()) {
                    isValidDate = true;
                }
                setInvalidDateMessage(!isValidDate);
            }
            updateWizardData(submissionVM);
        },
        [carRegisteredDate, updateWizardData, submissionVM, getPurchasedDateRegistrationDateValidator, monthList, vehicleVM.value]
    );

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition: 'top',
            phoneWide
        },
        [`regLookup${index}`]: {
            readOnly: _.includes(readOnlyFields, 'reglookup'),
            submitted: submitted,
            handleRegModeChanges: handleRegModeChanges,
        },
        [`securityTracker${index}`]: {
            availableValues: YESNOFieldavailableValues,
            showErrors: vehicleVM.securityTracker.value === undefined && submitted,
            validationMessages: vehicleVM.securityTracker.value === undefined && submitted ? requireFieldError : []

        },
        [`securityDevices${index}`]: {
            showErrors: vehicleVM.securityDevices.value === undefined && submitted,
            validationMessages: vehicleVM.securityDevices.value === undefined && submitted ? requireFieldError : []

        },
        [`imported${index}`]: {
            availableValues: YESNOFieldavailableValues,
            showErrors: vehicleVM.imported.value === undefined && submitted,
            validationMessages: vehicleVM.imported.value === undefined && submitted ? requireFieldError : []
        },
        [`registeredKeeper${index}`]: {
            availableValues: YESNOFieldavailableValues,
            showErrors: vehicleVM.registeredKeeper.value === undefined && submitted,
            validationMessages: vehicleVM.registeredKeeper.value === undefined && submitted ? requireFieldError : []
        },
        [`annualMileage${index}`]: {
            validationMessages: (vehicleVM.annualMileage.value == '' || vehicleVM.annualMileage.value == null) ? requireFieldError : []
        },
        [`classOfUse${index}`]: {
            showErrors: vehicleVM.classOfUse.value === undefined && submitted,
            validationMessages: vehicleVM.classOfUse.value === undefined && submitted ? requireFieldError : []
        },
        [`rightHandDrive${index}`]: {
            availableValues: YESNOFieldavailableValues,
            showErrors: vehicleVM.rightHandDrive.value === undefined && submitted,
            validationMessages: vehicleVM.rightHandDrive.value === undefined && submitted ? requireFieldError : []
        },
        [`lessThanEightSeats${index}`]: {
            availableValues: YESNOFieldavailableValues,
            showErrors: vehicleVM.lessThanEightSeats.value === undefined && submitted,
            validationMessages: vehicleVM.lessThanEightSeats.value === undefined && submitted ? requireFieldError : []
        },
        [`modification${index}`]: {
            availableValues: getModificationsAvailableValues(),
            onValueChange: handleModificationValueChange,
            value: getModificationChangeValue,
            showErrors: vehicleVM.modification.value === undefined && submitted,
            validationMessages: vehicleVM.modification.value === undefined && submitted ? requireFieldError : []
        },
        [`datePurchased${index}`]: {
            onValueChange: handleDatePurchasedValueChange,
            value: purchasedCarMonthYear,
            maxDate: {
                day: new Date().getDay(),
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear()
            },
            minDate: {
                day: new Date(minRegisteredYear).getDay(),
                month: new Date(minRegisteredYear).getMonth(),
                year: new Date(minRegisteredYear).getFullYear() - 1
            },
            showErrors: submitted && (showInvalidDateMessage || !purchasedCarMonthYear),
            validationMessages: purchasedDateValidationsMessage()
        },
        [`dayTimeParking${index}`]: {
            availableValues: !_.isEmpty(vehicleVM) ? vehicleVM.dayTimeParking.aspects.availableValues[0].typelist.getFilter('NonMigratingPoliciesDayTimeParking').codes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name
                })
            })) : [],
            showErrors: vehicleVM.dayTimeParking.value === undefined && submitted,
            validationMessages: vehicleVM.dayTimeParking.value === undefined && submitted ? requireFieldError : []
        },
        [`nightTimeParking${index}`]: {
            showErrors: vehicleVM.nightTimeParking.value === undefined && submitted,
            validationMessages: vehicleVM.nightTimeParking.value === undefined && submitted ? requireFieldError : []
        },
        [`marketValue${index}`]: {
            onValueChange: handleValueChange,
            validationMessages: (vehicleVM.marketValue?.amount?.value == '' || vehicleVM.marketValue?.amount?.value == null) ? requireFieldError : []
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    const data = {
        vehicle: vehicleVM
    };
    return (
        <ViewModelForm
            model={data}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            showErrors={submitted}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

PMVehicleComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    submitted: PropTypes.bool,
    id: PropTypes.string.isRequired
};
PMVehicleComponent.defaultProps = {
    data: {},
    labelPosition: 'left',
    phoneWide: {
        labelPosition: 'top'
    },
    path: undefined,
    readOnlyFields: [],
    showOptional: true,
    index: 0,
    submitted: false,
};
export default PMVehicleComponent;
