/**
 * Provides branding data from URL
 * using either 'branding' query string
 * or sub domain
 */
import queryString from 'query-string';

const BRANDS = [
    {host: 'insurethebox', brand: 'itb'},
    {host: 'tescobank', brand: 'tbb'},
    {host: 'tbboxins', brand: 'tbb'},
    {host: 'drivelikeagirl', brand: 'dgt'},
    {host: 'toyotamotorinsurance', brand: 'toy'},
    {host: 'lexusmotorinsurance', brand: 'lex'},
    {host: 'autosaninsurance', brand: 'aut'},
    {host: 'kinto-lcv-insurance', brand: 'kin'},
    {host: 'kwik-fit', brand: 'kfi'}
  ];

const hostNameFromURLsegments = window.location.hostname.split('.');

let hostName = BRANDS.find(function(hostBrands){
    return hostNameFromURLsegments.includes(hostBrands.host);
 });

 let hostNameBrand;
 if(hostName) {
    hostNameBrand = hostName.brand;
 }

export default {
    BRANDING: (
        // e.g. XXX?branding=custom gives "custom"
        queryString.parse(window.location.search).branding ||
            // e.g. custom-domain.guidewire.com gives "custom-domain"
            hostNameBrand || // e.g. custom-domain.guidewire.com gives  "custom-domain"
            window.brandingFromRequest // gets branding param value from request
    )
};
