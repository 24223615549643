import { defineMessages } from 'react-intl';

export default defineMessages({
    mainDriverInformation: {
        id: 'quoteandbind.views.contact-details.MainDriverInformation',
        defaultMessage: 'Main Driver Information',
    },
    pmDateOfBirth: {
        id: 'quoteandbind.views.contact-details.DateofBirth',
        defaultMessage: 'Date of Birth'
    },
    pmHowDidYouHearAboutUs: {
        id: 'quoteandbind.views.contact-details.HowDidYouHearAboutUs',
        defaultMessage: 'How did you hear about us?'
    },
    pmFirstNameTooltip: {
        id: 'platform.inputs.contact-details.FirstName.tooltip.content',
        defaultMessage: 'Here you can add helpful text related to the field. You can use links too, for example:'
    },
    pmLastNameTooltip: {
        id: 'platform.inputs.contact-details.LastName.tooltip.content',
        defaultMessage: 'Here you can add helpful text related to the field. You can use links too, for example:'
    },
    pmTooltipLink: {
        id: 'platform.inputs.contactdetails.tooltip.Link',
        defaultMessage: 'Link'
    },
    pmPleaseSelect: {
        id: 'quoteandbind.pM.views.common.PleaseSelect',
        defaultMessage: 'Please Select',
    },
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-about-you-error-one-of-two',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    }
});
