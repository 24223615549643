/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { MockUpUtil } from 'gw-portals-util-js';
import { Submission } from 'gw-capability-quoteandbind';
import { ErrorBoundary } from 'gw-portals-error-react';
import styles from './PMLegalPage2InformationMonthly.scss';
import { ScrollToError } from '@jutro/wizard-next';
import { withRouter } from 'react-router-dom';
import ValidationUtil from '../../util/ValidationUtil';

import metadata from './PMLegalPage2InformationMonthly.metadata.json5';
import messagesTranslationsSet from './PMLegalPage2InformationMonthlyPage.messages';

const PATH_TO_MOCK_DATA = 'quote.pm';
const MOCK_DATA_TO_SET = ['baseData.accountHolder.emailAddress1'];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

function initialiseVM(submissionVM) {
    submissionVM.bindData.paymentDetails.value = submissionVM.bindData.paymentDetails.value || {};
    submissionVM.bindData.paymentDetails.creditCardData.value = submissionVM.bindData.paymentDetails.creditCardData.value || {};
}

function PMLegalPage2InformationMonthlyPage(props) {
    const { wizardData: submissionVM, updateWizardData, goNext: history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { onValidate } = useValidation('PMLegalPage2InformationMonthlyPage');
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];

    const submission = useMemo(() => new Submission(submissionVM.value), [submissionVM.value]);

    const installments = submission.getInstallmentsPaymentPlan_AND();
    const isCardRegisteredToYouValue = _.get(submissionVM.value, 'bindData.paymentDetails.creditCardData.isCardRegisteredToYou');
    const isCardRegisteredToYouBoolean = typeof isCardRegisteredToYouValue === 'boolean';
    const [isThisCardRegisteredToYou, setThisCardRegisteredToYou] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const isHomeNumberValid = ValidationUtil.isHomeNumberValid(submissionVM);
    const isPhoneNumberValid = ValidationUtil.isPhoneNumberValid(submissionVM);
    const [isPhoneNumberLnPValid, setPhoneNumberLnPValid] = useState(ValidationUtil.isPhoneNumberLnPValid(submissionVM));
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'PMLegalPage2InformationMonthly', {
        confirmationCheckBox: _.get(submissionVM, 'bindData.boxDeclaration_itb.value'),
    }, isPhoneNumberLnPValid);
    const requireFieldError = ['This field is required'];

    useEffect(() => {
        if (!isVMInitialised) {
            initialiseVM(submissionVM);
            updateIsVMInitialised(true);
        } else {
            let vm = viewModelService.clone(submissionVM);
            _.set(vm.value, 'bindData.autoRenew_itb', true);

            if (!_.isEqual(vm.value, submissionVM.value)) {
                updateWizardData(vm);
            }
        }

        const bankAccountDataDetails = _.get(submissionVM, 'bindData.paymentDetails.value.bankAccountData');
        if (bankAccountDataDetails === undefined) {
            _.set(submissionVM, 'bindData.paymentDetails.value.bankAccountData', {});
        }
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(6));
    }, [isVMInitialised, submissionVM, updateWizardData, viewModelService]);

    const updateQuoteAndValidateDDI = useCallback(() => {
        const isCardRegisteredToYou = _.get(submissionVM, 'bindData.paymentDetails.creditCardData.isCardRegisteredToYou.value');
        const cardHolderFirstname = _.get(submissionVM, 'bindData.paymentDetails.creditCardData.cardHolderFirstname.value');
        const cardHolderSurname = _.get(submissionVM, 'bindData.paymentDetails.creditCardData.cardHolderSurname.value');
        const cardHolderPhoneNumber = _.get(submissionVM, 'bindData.paymentDetails.creditCardData.cardHolderPhoneNumber.value');
        const cardHolderEmailAddress = _.get(submissionVM, 'bindData.paymentDetails.creditCardData.cardHolderEmailAddress.value');
        const chosenQuote = _.get(submissionVM, 'bindData.chosenQuote.value');
        return LoadSaveService.updateQuotedSubmissionAndValidateDDI_AND(submissionVM.value)
            .then((response) => {
                if (!response.bindData.paymentDetails.bankAccountData.validationResponse_itb.isCorrect) {
                    return false;
                    // eslint-disable-next-line no-else-return
                } else {
                    _.set(submissionVM, 'bindData.value', response.bindData);
                    _.set(submissionVM, 'bindData.chosenQuote.value', chosenQuote);
                    // Credit Card object must be reconstructed when returned empty because of the input ctrls
                    submissionVM.bindData.paymentDetails.creditCardData.value =
                        submissionVM.bindData.paymentDetails.creditCardData.value || {
                            isCardRegisteredToYou, cardHolderFirstname, cardHolderSurname, cardHolderPhoneNumber, cardHolderEmailAddress
                        };
                    return response;
                }
            })
            .catch((error) => {
                submissionVM.bindData.paymentDetails.bankAccountData.validationResponse_itb.value = {
                    integrationOffline: true
                };
                history.push({
                    pathname: '/error',
                    data: error,
                    origin: "PMLegalPage2InformationMonthlyPage [updateQuoteAndValidateDDI]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            });
    }, [LoadSaveService, history, submissionVM]);

    const onNext = useCallback(async () => {
        if (invalidPropsLength > 0) {
            setErrorTimestamp(Date.now());
            setIsSubmitted(true);
            return false;
        }
        if (submissionVM.value.isInstallmentsPaymentPlan_AND()) {
            const result = await updateQuoteAndValidateDDI();
            if (!result) {
                return false;
            }
        }
        try {
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
            const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newUpdatedSubmissionVM);
            return newUpdatedSubmissionVM;
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            history.push({
                pathname: '/error',
                data: error,
                origin: "PMLegalPage2InformationMonthlyPage  [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [history, invalidPropsLength, submissionVM, updateQuoteAndValidateDDI, updateWizardData, viewModelService]);

    const handleMobileNumChange = useCallback(
        (value, path) => {
            const pattern = /^[0-9]{0,11}$/;
            const allowTestNums = /^(07700900[0-9]{3})|(07[0-9]{9})$/;
            if (pattern.test(value)) {
                if (allowTestNums.test(value)) {
                    setPhoneNumberLnPValid(true);
                } else {
                    setPhoneNumberLnPValid(false);
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onNameHandleValueChange = (value, path) => {
        const pattern = /^[a-zA-Z-' ]{0,25}$/;
        if (pattern.test(value)) {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
        }
    };
    const handleAccountHolderName = useCallback(
        (value, path) => {
            const pattern = /^[a-zA-Z-' ]*(?: [a-zA-Z-' ]*){0,1}$/;
            if (value.length <= 51 && pattern.test(value)) {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleAccountNumber = useCallback(
        (value, path) => {
            const pattern = /^[0-9]{0,8}$/;
            if (pattern.test(value)) {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleSortCode = useCallback(
        (value, path) => {
            let formattedValue = value;
            formattedValue = formattedValue.replace(/-/g, '');
            const pattern = /^(\d{0,6}|)$/;
            if (pattern.test(formattedValue) && formattedValue.length <= 6) {
                if (formattedValue.length === 6) {
                    formattedValue = `${formattedValue.substring(0, 2)}-${formattedValue.substring(2, 4)}-${formattedValue.substring(4)}`;
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, path, formattedValue);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleThisCardRegisteredToYou = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, path, value);
            updateWizardData(newSubmissionVM);
            setThisCardRegisteredToYou(!value);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const overrideProps = {
        '@field': {
            showOptional: false,
        },
        inlineNotificationErrorBarLP2of4: {
            message: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, isSubmitted).strErrorBannerTextOutput,
            visible: ValidationUtil.updateInlineErrorBanner(submissionVM, messagesTranslationsSet, invalidPropsLength, isSubmitted).blnErrorWidgetVisibility
        },
        checkboxField: {
            showErrors: !_.get(submissionVM.value, 'bindData.boxDeclaration_itb') && isSubmitted,
            validationMessages: !_.get(submissionVM.value, 'bindData.boxDeclaration_itb') && isSubmitted ? ['Please fill out this field'] : []

        },
        isCardRegisteredToYou: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleThisCardRegisteredToYou,
            showErrors: !isCardRegisteredToYouBoolean && isSubmitted,
            validationMessages: !isCardRegisteredToYouBoolean && isSubmitted ? requireFieldError : []
        },
        carddisclaimer: {
            visible: isCardRegisteredToYouBoolean,
            content: `${isThisCardRegisteredToYou ? '' : '*'} Please note: If you are not the cardholder it is your responsibility to ensure that you have the cardholder's authority to make this payment and for this card to be used for automatic renewal. You must also advise the cardholder of any changes to the premium.`
        },
        isCardRegisteredToYouContainer: {
            visible: !isCardRegisteredToYouValue && isCardRegisteredToYouBoolean
        },
        firstName: {
            onValueChange: onNameHandleValueChange,
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.creditCardData.cardHolderFirstname') ? requireFieldError : [],
            visible: !isCardRegisteredToYouValue && isCardRegisteredToYouBoolean

        },
        surName: {
            onValueChange: onNameHandleValueChange,
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.creditCardData.cardHolderSurname') ? requireFieldError : [],
            visible: !isCardRegisteredToYouValue && isCardRegisteredToYouBoolean
        },
        emailAddress: {
            validator: {
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/,
                message: {
                    id: 'emailValidationMessage',
                    defaultMessage: 'This field is required'
                }
            },
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.creditCardData.cardHolderEmailAddress') ? requireFieldError : [],
            visible: !isCardRegisteredToYouValue && isCardRegisteredToYouBoolean
        },
        PhoneNumber: {
            onValueChange: handleMobileNumChange,
            validator: {
                pattern: /^(07700900[0-9]{3})|(07[0-9]{9})$/,
                message: {
                    id: 'mobileValidationMessage',
                    defaultMessage: 'Value entered must be a valid phone number'
                }
            },
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.creditCardData.cardHolderPhoneNumber') ? requireFieldError : [],
            visible: !isCardRegisteredToYouValue && isCardRegisteredToYouBoolean
        },
        billingaddressLine1: {
            validationMessages: !_.get(submissionVM.value, 'bindData.billingAddress.addressLine1') ? requireFieldError : []
        },
        billingpostalCode: {
            validationMessages: !_.get(submissionVM.value, 'bindData.billingAddress.postalCode') ? requireFieldError : []
        },
        accountHolderName: {
            onValueChange: handleAccountHolderName,
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData.accountHolderName') ? requireFieldError : []
        },
        accountNumber: {
            onValueChange: handleAccountNumber,
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData.bankAccountNumber') ? requireFieldError : []
        },
        sortCode: {
            onValueChange: handleSortCode,
            validationMessages: !_.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData.sortCode') ? requireFieldError : []
        },
        accountType: { // eslint-disable-next-line no-nested-ternary
            validationMessages: (!_.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData.bankAccountType')) ? ['Please fill out this field'] : _.get(submissionVM.value, 'bindData.paymentDetails.bankAccountData.bankAccountType') === 'company' ? ['Please provide details of a personal/individual bank account in order to pay the instalments for this policy. A company bank account cannot be used'] : []
        },
        isAuthorisedPayment: {
            availableValues: YESNOFieldavailableValues,
        },
        FirstPaymentDetailsContentTitle: {
            content: `Your first payment of £${installments.downPayment.amount.toFixed(2)} is due today. Please enter the billing details for the card payment.`
        },
        BankAccountDetailsContentDescription1: {
            content: `You have chosen a monthly payment of £${installments.installment.amount.toFixed(2)} Please enter the bank account details for Direct Debit installments.`
        },
        monthlyRepresentativeAnnualPremiumValue: {
            content: `£${installments.totalPremiumRPT.amount.toFixed(2)}`
        },
        monthlyRepresentativeDepositPayableValue: {
            content: `£${installments.downPayment.amount.toFixed(2)}`
        },
        monthlyRepresentativeAnnualAmountOfCreditValue: {
            content: `£${installments.creditAmount_itb.amount.toFixed(2)}`
        },
        monthlyRepresentativeAnnualInterestRateValue: {
            content: `${installments.interestRate_itb}%`
        },
        monthlyRepresentativeAnnualTransactionFeeValue: {
            content: `£${installments.fees_itb.amount.toFixed(2)}`
        },
        monthlyRepresentativeAnnualMonthlyPaymentsValue: {
            content: `£${installments.installment.amount.toFixed(2)}`
        },
        monthlyRepresentativeAnnualTotalAmountPayableValue: {
            content: `£${installments.total.amount.toFixed(2)}`
        },
        monthlyRepresentativeAnnualRepresentativeAPRValue: {
            content: `${installments.aprPercentage_itb} APR variable`
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };
    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "PMLegalPage2InformationMonthlyPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                disableNext={false}
                nextLabel={'Continue'}
                previousLabel={'Back'}
                disableCancel={true}
                cancelLabel={''}>

                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    showErrors={isSubmitted}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </WizardPage>
        </ErrorBoundary>
    );
}

PMLegalPage2InformationMonthlyPage.propTypes = wizardProps;
export default withRouter(PMLegalPage2InformationMonthlyPage);
