import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { withRouter } from 'react-router-dom';
import DDViewMandateModalPopup from './DDViewMandateModalPopup';

import styles from './PMLegalPage3InformationPage.scss';
import metadata from './PMLegalPage3InformationPage.metadata.json5';
import './PMLegalPage3InformationPage.messages';

const SERVICE_NUMBER = '942461';
const REFERENCE_NUMBER = '          ';

function PMLegalPage3InformationPage(props) {
    const { wizardData: submissionVM, updateWizardData, steps, jumpTo, history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PMLegalPage3InformationPage');
    const [isOpen, setIsOpen] = useState(false);

    const handleModalOpen = () => setIsOpen(true);

    const handleModalClose = () => setIsOpen(false);

    const navigateToPreviousPage = useCallback((pathname) => {
        const indexOfPage = _.findIndex(
            steps,
            ({ path }) => path === pathname
        );
        updateWizardData(submissionVM);
        jumpTo(indexOfPage);
    }, [jumpTo, steps, submissionVM, updateWizardData]);

    const onNext = useCallback(async () => {
        try {
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
            const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newUpdatedSubmissionVM);
            return newUpdatedSubmissionVM;
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            history.push({
                pathname: '/error',
                data: error,
                origin: "PMLegalPage3InformationMonthlyPage [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [history, submissionVM, updateWizardData, viewModelService]);

    const handlePrint = useCallback(() => {
        try {
            window.print();
        } catch (error) {
            console.error(error);
        }
    }, []);

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top'
        },
        editMandate: {
            onClick: () => {
                if (navigateToPreviousPage) {
                    navigateToPreviousPage('legal-and-info-2-monthly');
                }
            }
        },
        viewCompleteMandate: {
            onClick: () => {
                handleModalOpen();
            }
        },
        ddMandateDate: {
            value: Date.now()
        },
        ServiceNumTable: {
            stringValue: SERVICE_NUMBER
        },
        ReferenceNumTable: {
            stringValue: REFERENCE_NUMBER
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };
    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "PMLegalPage3InformationMonthlyPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                onNext={onNext}
                disableNext={!isComponentValid}
                nextLabel={'Continue'}
                previousLabel={'Back'}
                disableCancel={true}
                cancelLabel={''}>
                <DDViewMandateModalPopup
                    isOpen={isOpen}
                    data={submissionVM}
                    serviceNum={SERVICE_NUMBER}
                    refNum={REFERENCE_NUMBER}
                    handleClose={handleModalClose}
                    onPrint={handlePrint}
                />
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </WizardPage>
        </ErrorBoundary>
    );
}

PMLegalPage3InformationPage.propTypes = wizardProps;

export default withRouter(PMLegalPage3InformationPage);
