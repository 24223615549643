/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';

import metadata from './PMQuoteReviewDriverComponent.metadata.json5';
import './PMQuoteReviewDriverComponent.messages';
import styles from '../Stylesheets/PMQuoteReview.module.scss';

function formatDate(value) {
    return (`0${value}`).slice(-2);
}

function PMQuoteReviewDriverComponent(props) {
    const {
        data: driverVM,
        path,
        id,
        index,
        labelPosition,
        onValueChange,
        showOptional,
        phoneWide
    } = props;
    const driver = driverVM.value;
    const translator = useTranslator();
    const doB = _.get(driverVM, 'dateOfBirth.value');
    const displayName = useCallback((driver) => {
        let availableValues = _.get(driverVM, 'title_itb.aspects.availableValues');
        let title = availableValues.find((item) => item.code === driver.title_itb);

        if (title) {
            return `${translator({
                id: title.name,
                defaultMessage: title.name
            })} ${driver.firstName} ${driver.lastName}`;
        } else {
            return `${driver.firstName} ${driver.lastName}`;
        }
    }, [driverVM, translator]);
    const occupation = useCallback((driver) => {
        let availableValues = _.get(driverVM, 'occupation_itb.aspects.availableValues');
        let occupation = availableValues.find((item) => item.code === driver.occupation_itb);
        return occupation ? translator({
            id: occupation.name,
            defaultMessage: occupation.name
        }) : '';
    }, [driverVM, translator]);
    const occupationStatus = useCallback((driver) => {
        let availableValues = _.get(driverVM, 'occupationStatus_itb.aspects.availableValues');
        let occupationStatus = availableValues.find((item) => item.code === driver.occupationStatus_itb);
        return occupationStatus ? translator({
            id: occupationStatus.name,
            defaultMessage: occupationStatus.name
        }) : '';
    }, [driverVM, translator]);
    const employersBuisness = useCallback((driver) => {
        let availableValues = _.get(driverVM, 'employersBusiness_itb.aspects.availableValues');
        let employersBuisness = availableValues.find((item) => item.code === driver.employersBusiness_itb);
        return employersBuisness ? translator({
            id: employersBuisness.name,
            defaultMessage: employersBuisness.name
        }) : '';
    }, [driverVM, translator]);
    const maritalStatus = useCallback((driver) => {
        let availableValues = _.get(driverVM, 'maritalStatus.aspects.availableValues');
        let maritalStatus = availableValues.find((item) => item.code === driver.maritalStatus);
        return maritalStatus ? translator({
            id: maritalStatus.name,
            defaultMessage: maritalStatus.name
        }) : '';
    }, [driverVM, translator]);
    const licenseType = useCallback((driver) => {
        let availableValues = _.get(driverVM, 'licenseType.aspects.availableValues');
        let licenseType = availableValues.find((item) => item.code === driver.licenseType);
        return licenseType ? translator({
            id: licenseType.name,
            defaultMessage: licenseType.name
        }) : '';
    }, [driverVM, translator]);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );
    const overrideProps = useMemo(() => ({
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
            readOnly: true
        },
        [`isMainDriver${index}`]: {
            value: displayName(driver) && `${displayName(driver)} ${driver.mainDriver ? '(Main Driver)' : ''}`
        },
        [`dateOfBirthDriver${index}`]: {
            value: _.get(driver, 'dateOfBirth') ? `${formatDate(doB.day)}-${formatDate(doB.month + 1)}-${doB.year}` : ''
        },
        [`occupationDriver${index}`]: {
            value: occupation(driver)
        },
        [`occupationStatus_itbDriver${index}`]: {
            value: occupationStatus(driver)
        },
        [`employersBuisness_itbDriver${index}`]: {
            value: employersBuisness(driver)
        },
        [`maritalStatusDriver${index}`]: {
            value: maritalStatus(driver)
        },
        [`ukResidencyDriver${index}`]: {
            value: driver.ukResident5Years_itb ? 'More than 5 years' : 'Less than 5 years'
        },
        [`licenseTypeDriver${index}`]: {
            value: licenseType(driver)
        },
        [`accessToOtherVehiclesDriver${index}`]: {
            value: driver.accessToOtherVehicles ? 'Yes' : 'No'
        },
        [`drivingQualificationsDriver${index}`]: {
            value: driver.drivingQualifications
        },
        [`medicalConditionsDriver${index}`]: {
            value: driver.medicalConditions
        },
        [`unspentOffencesDriver${index}`]: {
            value: driver.nonMotoringConvictions ? 'Yes' : 'No'
        },
        [`PreviouslyCancelledDriver${index}`]: {
            value: driver.cancelledPreviousInsurance ? 'Yes' : 'No'
        },
        [`convictionsDriver${index}`]: {
            value: driver.hasMotorConvictions ? 'Yes' : 'No'
        },
        [`claimsDriver${index}`]: {
            value: driver.hasMotorClaims ? 'Yes' : 'No'
        },
    }), [showOptional, labelPosition, phoneWide, displayName, driver, doB, index, occupation, occupationStatus, employersBuisness, maritalStatus, licenseType]);

    const readValue = useCallback(
        (fieldId, fieldPath) => {
            return readViewModelValue(
                formattedMetadata,
                driverVM,
                fieldId,
                fieldPath,
                overrideProps
            );
        },
        [driverVM, formattedMetadata, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };
    return (
        <ViewModelForm
            model={driverVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMQuoteReviewDriverComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMQuoteReviewDriverComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMQuoteReviewDriverComponent;
