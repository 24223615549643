import { defineMessages } from 'react-intl';

export default defineMessages({
    generalCoverages: {
        id: 'quoteandbind.pm.directives.MultiQuoteView.General Coverages - Apply to all vehicles',
        defaultMessage: 'General Coverages - Apply to all vehicles',
    },
    vehicleSpecificCoverage: {
        id: 'quoteandbind.pm.directives.MultiQuoteView.Vehicle-',
        defaultMessage: 'Vehicle-Specific Coverages - ',
    },
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-your-quote-error.banner text',
        defaultMessage: 'Please correct the errors on this page before continuing. The errors are shown in red.',
    }
});
