import React from 'react';
import styles from './PMQuotePage_Coverage.module.scss';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';
// eslint-disable-next-line import/prefer-default-export
const legalExpenseCov = {
    code: 'PMLegalExpensesCov',
    title: 'Legal Expenses Cover',
    name: '<strong>Legal Expenses Cover</strong> provides:',
    pretext: '',
    bulletPoints: [
        'Up to £100,000 for legal costs and expenses to claim compensation for personal injury or losses from a person responsible for an accident',
        'Up to £50,000 to cover legal costs if you are investigated in relation to, or charged with a motoring offence',
    ],
    paragraphs: [
        'Eligible claims must have at least a 51% chance of success. Costs that you incur without our consent or which exceed the sum we would pay a law firm from our panel, parking infringements or non-payment of parking fines, and fines, penalties or compensation ordered against you are not covered.',
        `View the <a href="${DocUtil.getFileUrl('tya','LEGAL_EXP_IPID')}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions. You can access the <a href="${DocUtil.getFileUrl('tya','LEGAL_EXP_POLICY')}" target="_blank">Policy Document</a> for full terms and conditions.`
    ],
    imgURL: (<div className={styles.LegalExpensesSVGIcon} id="legalExpensesCoverElement">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1}/>
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2}/>
            <path d="M125,37,53,69v48c0,44.42,30.73,86,72,96,41.3-10.08,72-51.62,72-96V69Z" className={styles.covIconLayer3}/>
            <path d="M92.13,152.9h37.19v6.2H92.13Zm13.14-40.08,8.77-8.77,43.83,43.83-8.77,8.77Zm22-21.92,17.54,17.55L136,117.22,118.44,99.68ZM100.9,117.19l17.54,17.54-8.77,8.78L92.13,126Z" className={styles.covIconLayer4}/>
        </svg>
</div>)
};

const ECCCCov = {
    code: 'TMEnhancedCourtesyCarCov',
    title: 'Enhanced Courtesy Car Cover',
    name: '<strong>Included in Enhanced Courtesy Car cover is a courtesy car for up to 21 days if your car is declared:</strong>',
    pretext: '',
    bulletPoints: [
        'Unrepairable following an accident that was your fault or as a result of fire, lightning, flood, vandalism, explosion or attempted theft, or;',
        'Stolen and not recovered',
    ],
    paragraphs: [
        'To be eligible for a courtesy car you must have held a full driving licence for at least 6 months, and not work as an entertainer or professional sports person. If you are not eligible for a courtesy car, you’ll receive up to £250 towards alternative transport costs.',
        'A £350 excess applies for most claims. A £75 + VAT excess applies for glass claims.',
        `View the <a href="${DocUtil.getFileUrl('tya','ECCC_IPID')}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions. You can access the <a href="${DocUtil.getFileUrl('tya','ECCC_POLICY')}" target="_blank">Policy Document</a> for full terms and conditions.`
    ],
    imgURL: (<div className={styles.CourtesyCarSVGIcon} id="enhancedCourtesyCarCoverElement">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1}/>
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2}/>
            <path d="M197,124.77l-.77-4.25a3.41,3.41,0,0,0-3.34-2.84h-12l-7-16.46a9.81,9.81,0,0,0-8.8-5.72H84.89a9.8,9.8,0,0,0-8.8,5.72l-7,16.46h-12a3.41,3.41,0,0,0-3.34,2.84L53,124.77a3.46,3.46,0,0,0,2.28,3.9l2.72.9a3.47,3.47,0,0,1,1.67,5.33l-2.22,3a3.47,3.47,0,0,0-.66,2l-.17,54.72h0a3.42,3.42,0,0,0,3.41,3.43H72.61a3.42,3.42,0,0,0,3.4-3.44V181.05h98v13.66a3.42,3.42,0,0,0,3.4,3.44H190a3.43,3.43,0,0,0,3.4-3.44L193.19,140a3.47,3.47,0,0,0-.66-2l-2.22-3a3.47,3.47,0,0,1,1.67-5.33l2.72-.9A3.46,3.46,0,0,0,197,124.77Z" className={styles.covIconLayer3}/>
            <path d="M71.38,151.64l-.78,4a3.37,3.37,0,0,0,3.26,4l17.11.24a3.37,3.37,0,0,0,3.3-2.47h0a3.37,3.37,0,0,0-2.39-4.16L75.55,149A3.39,3.39,0,0,0,71.38,151.64Z" className={styles.covIconLayer4}/>
            <path d="M178.62,151.64l.78,4a3.37,3.37,0,0,1-3.26,4l-17.11.24a3.37,3.37,0,0,1-3.3-2.47h0a3.37,3.37,0,0,1,2.39-4.16L174.45,149A3.39,3.39,0,0,1,178.62,151.64Z" className={styles.covIconLayer5}/>
            <path d="M167.53,106.77a8.53,8.53,0,0,0-7.64-4.73H90.11a8.53,8.53,0,0,0-7.64,4.73L70.54,133.61H179.46Z" className={styles.covIconLayer6}/>
            <path d="M125,162.88H101.47a1.38,1.38,0,0,0-1.32,1.77l1.94,6.52a1.37,1.37,0,0,0,1.32,1h43.18a1.37,1.37,0,0,0,1.32-1l1.94-6.52a1.38,1.38,0,0,0-1.32-1.77Z" className={styles.covIconLayer7}/>
            <path d="M125,51.85,93.26,66V87.11c0,19.57,13.54,37.87,31.74,42.32,18.2-4.45,31.74-22.75,31.74-42.32V66Z" className={styles.covIconLayer8}/>
            <path d="M125,51.85,93.26,66V87.11c0,19.57,13.54,37.87,31.74,42.32,18.2-4.45,31.74-22.75,31.74-42.32V66Zm-7,56.42L103.84,94.16l5-5,9.14,9.1,23.24-23.24,5,5Z" className={styles.covIconLayer9}/>
        </svg>
</div>)
};

const TrailerCov = {
    code: 'PMTrailerCov',
    title: 'Trailer Cover',
    name: '<strong>Trailer Cover provides:</strong>',
    pretext: '',
    bulletPoints: [],
    paragraphs: [
        'This cover will pay the cost of repairs to your trailer or make a cash payment in the event of accidental damage or loss, or if your trailer is stolen but not recovered.',
        'You can find out more about this cover and full details of any exclusions in sections 1 and 2 of the Private Car Policy Document.',
        'Policy Excesses apply for any claims under this cover.'
    ],
    imgURL: (<div className={styles.TrailerSVGIcon} id="trailerCoverElement">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1}/>
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2}/>
            <path d="M67.37,74.58A14.44,14.44,0,0,0,53,89v57.63A14.45,14.45,0,0,0,67.37,161H89.43a17.91,17.91,0,0,0,35.12,0H197V146.61H168.22V89a14.44,14.44,0,0,0-14.41-14.4Z" className={styles.covIconLayer3}/>
            <path d="M67.37,74.58A14.44,14.44,0,0,0,53,89v57.63A14.45,14.45,0,0,0,67.37,161H89.43a17.91,17.91,0,0,0,35.12,0H197V146.61H168.22V89a14.44,14.44,0,0,0-14.41-14.4ZM71,89H99.79a3.4,3.4,0,0,1,3.6,3.6v21.61a3.4,3.4,0,0,1-3.6,3.61H71a3.4,3.4,0,0,1-3.6-3.61V92.58A3.4,3.4,0,0,1,71,89Zm50.43,0h28.81a3.4,3.4,0,0,1,3.6,3.6v21.61a3.4,3.4,0,0,1-3.6,3.61H121.4a3.4,3.4,0,0,1-3.6-3.61V92.58A3.4,3.4,0,0,1,121.4,89ZM107,150.21a7.21,7.21,0,1,1-7.2,7.21A6.81,6.81,0,0,1,107,150.21Z" className={styles.covIconLayer4}/>
        </svg>
 </div>)
};

const DrivingAbroadCov = {
    code: 'PMDrivingAbroadCov',
    title: 'Driving Abroad Cover',
    name: '<strong>90 days Driving Abroad provides:</strong>',
    pretext: 'Your policy covers you to drive in the EU/EEA for a maximum period of 3 days. If you are looking to drive outside the UK for more than 3 days, you can add our Driving Abroad Extension cover to give you a maximum of 90 days EU/EEA cover.',
    bulletPoints: [],
    paragraphs: [
        'The full benefits of your policy apply whilst driving in the EU/EEA, including when your car is being transported.',
        'If you wish to drive abroad, you must ensure that you have any documentation required to evidence insurance.',
        'Temporary replacement cars are not available abroad and repairs undertaken abroad are not guaranteed as they are not carried out by our approved network of repairers.',
        'Please see Section 6 of the Private Car Policy Document for full details of the cover terms and exclusions that apply.'
    ],
    imgURL: (<div className={styles.DrivingAbroadSVGIcon} id="drivingAbroadCoverElement">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1} />
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2} />
            <path d="M197,124.77l-.77-4.25a3.41,3.41,0,0,0-3.34-2.84h-12l-7-16.46a9.81,9.81,0,0,0-8.8-5.72H84.89a9.8,9.8,0,0,0-8.8,5.72l-7,16.46h-12a3.41,3.41,0,0,0-3.34,2.84L53,124.77a3.46,3.46,0,0,0,2.28,3.9l2.72.9a3.47,3.47,0,0,1,1.67,5.33l-2.22,3a3.47,3.47,0,0,0-.66,2l-.17,54.72h0a3.42,3.42,0,0,0,3.41,3.43H72.61a3.42,3.42,0,0,0,3.4-3.44V181.05h98v13.66a3.42,3.42,0,0,0,3.4,3.44H190a3.43,3.43,0,0,0,3.4-3.44L193.19,140a3.47,3.47,0,0,0-.66-2l-2.22-3a3.47,3.47,0,0,1,1.67-5.33l2.72-.9A3.46,3.46,0,0,0,197,124.77Z" className={styles.covIconLayer3} />
            <path d="M71.38,151.64l-.78,4a3.37,3.37,0,0,0,3.26,4l17.11.24a3.37,3.37,0,0,0,3.3-2.47h0a3.37,3.37,0,0,0-2.39-4.16L75.55,149A3.39,3.39,0,0,0,71.38,151.64Z" className={styles.covIconLayer4} />
            <path d="M178.62,151.64l.78,4a3.37,3.37,0,0,1-3.26,4l-17.11.24a3.37,3.37,0,0,1-3.3-2.47h0a3.37,3.37,0,0,1,2.39-4.16L174.45,149A3.39,3.39,0,0,1,178.62,151.64Z" className={styles.covIconLayer5} />
            <path d="M167.53,106.77a8.53,8.53,0,0,0-7.64-4.73H90.11a8.53,8.53,0,0,0-7.64,4.73L70.54,133.61H179.46Z" className={styles.covIconLayer6} />
            <path d="M125,162.88H101.47a1.38,1.38,0,0,0-1.32,1.77l1.94,6.52a1.37,1.37,0,0,0,1.32,1h43.18a1.37,1.37,0,0,0,1.32-1l1.94-6.52a1.38,1.38,0,0,0-1.32-1.77Z" className={styles.covIconLayer7} />
            <circle cx="125" cy="90.64" r="38.79" className={styles.covIconLayer8} />
            <polygon points="146.61 75.46 147.98 72.53 149.35 75.46 152.49 75.87 150.16 78.24 150.71 81.44 147.97 79.91 145.1 81.44 145.65 78.24 143.46 75.87 146.61 75.46" className={styles.covIconLayer9} />
            <polygon points="94.37 89.66 97.51 89.1 98.88 86.18 100.25 89.1 103.39 89.66 101.2 91.89 101.61 95.09 98.88 93.56 96.14 95.09 96.69 91.89 94.37 89.66" className={styles.covIconLayer10} />
            <polygon points="104.9 107.07 102.03 105.53 99.29 107.07 99.84 103.87 97.51 101.64 100.66 101.08 102.03 98.3 103.39 101.08 106.53 101.64 104.34 103.87 104.9 107.07" className={styles.covIconLayer11} />
            <polygon points="104.36 78.24 104.9 81.44 102.03 79.92 99.29 81.45 99.84 78.25 97.51 75.87 100.66 75.46 102.03 72.53 103.39 75.46 106.53 75.87 104.36 78.24" className={styles.covIconLayer12} />
            <polygon points="114.34 116.26 111.6 114.73 108.87 116.26 109.28 113.06 107.09 110.69 110.23 110.27 111.6 107.35 112.97 110.27 116.11 110.69 113.78 113.06 114.34 116.26" className={styles.covIconLayer13} />
            <polygon points="113.79 69.19 114.34 72.4 111.6 70.86 108.87 72.4 109.28 69.19 107.09 66.96 110.23 66.41 111.6 63.62 112.97 66.41 116.11 66.96 113.79 69.19"className={styles.covIconLayer14} />
            <polygon points="127.74 121.83 125 120.3 122.27 121.83 122.68 118.63 120.49 116.4 123.63 115.98 125 113.05 126.37 115.98 129.52 116.4 127.33 118.62 127.74 121.83" className={styles.covIconLayer15} />
            <polygon points="127.33 65.01 127.74 68.21 125 66.69 122.27 68.22 122.68 65.02 120.49 62.79 123.63 62.37 125 59.44 126.37 62.37 129.52 62.79 127.33 65.01" className={styles.covIconLayer16} />
            <polygon points="141.14 116.26 138.41 114.73 135.67 116.26 136.22 113.06 133.89 110.69 137.03 110.27 138.4 107.35 139.77 110.27 142.91 110.69 140.72 113.06 141.14 116.26" className={styles.covIconLayer17} />
            <polygon points="140.73 69.19 141.14 72.4 138.41 70.86 135.67 72.4 136.22 69.19 133.9 66.96 137.04 66.41 138.41 63.62 139.77 66.41 142.92 66.96 140.73 69.19" className={styles.covIconLayer18} />
            <polygon points="150.72 107.07 147.98 105.53 145.11 107.07 145.66 103.87 143.47 101.64 146.61 101.08 147.97 98.3 149.34 101.08 152.49 101.64 150.16 103.87 150.72 107.07" className={styles.covIconLayer19} />
            <polygon points="153.86 95.09 151.13 93.56 148.39 95.09 148.8 91.89 146.61 89.67 149.75 89.11 151.12 86.18 152.49 89.11 155.63 89.67 153.3 91.89 153.86 95.09" className={styles.covIconLayer20} />
        </svg>
    </div>)
};

const Breakdown_NationalPlusDoorstepCov = {
    code: 'TMBreakdownCov',
    title: 'Breakdown Cover',
    name: '<strong>Road Rescue - National plus Doorstep</strong> provides:',
    pretext: '',
    term: 'Level2',
    bulletPoints: [
        '<strong>Roadside Assistance</strong><br/>Breakdown assistance at the roadside when you`re over 1 mile away from your home.',
        '<strong>Breakdown Assistance at Home</strong><br/>Breakdown assistance if your car won`t start at home or within 1 mile of your home.',
        '<strong>Local Recovery</strong><br/>If your car cannot be repaired at the roadside, we`ll transport your car and its passengers to any single destination in the UK.',
        '<strong>Onward Travel</strong><br/>If your car cannot be repaired at the roadside or a local garage by the end of the working day - we’ll either arrange and pay to take you and your car to any UK destination or provide the option of 1 day’s hire car or up to £100 for alternative transport/ overnight accommodation to allow you to continue your journey. Age and licence restrictions apply for the hire car benefit.<br/><br/>If your car is stolen and not recovered by the end of the working day, for you to be transported home or to your original destination.'
    ],
    paragraphs: [
        `Read the <a href="${DocUtil.getFileUrl('tya','BREAKDOWN_IPID_BASIC')}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions.`
    ],
    policyDocumentText: `You can access the <a href="${DocUtil.getFileUrl('tya','BREAKDOWN_POLICY')}" target="_blank">Policy Document</a> for full terms and conditions.`,
    imgURL: (<div className={styles.BreakdownSVGIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1} />
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2} />
            <path d="M197,124.77l-.77-4.25a3.41,3.41,0,0,0-3.34-2.84h-12l-7-16.46a9.81,9.81,0,0,0-8.8-5.72H84.89a9.8,9.8,0,0,0-8.8,5.72l-7,16.46h-12a3.41,3.41,0,0,0-3.34,2.84L53,124.77a3.46,3.46,0,0,0,2.28,3.9l2.72.9a3.47,3.47,0,0,1,1.67,5.33l-2.22,3a3.47,3.47,0,0,0-.66,2l-.17,54.72h0a3.42,3.42,0,0,0,3.41,3.43H72.61a3.42,3.42,0,0,0,3.4-3.44V181.05h98v13.66a3.42,3.42,0,0,0,3.4,3.44H190a3.43,3.43,0,0,0,3.4-3.44L193.19,140a3.47,3.47,0,0,0-.66-2l-2.22-3a3.47,3.47,0,0,1,1.67-5.33l2.72-.9A3.46,3.46,0,0,0,197,124.77Z" className={styles.covIconLayer3} />
            <path d="M71.38,151.64l-.78,4a3.37,3.37,0,0,0,3.26,4l17.11.24a3.37,3.37,0,0,0,3.3-2.47h0a3.37,3.37,0,0,0-2.39-4.16L75.55,149A3.39,3.39,0,0,0,71.38,151.64Z" className={styles.covIconLayer4} />
            <path d="M178.62,151.64l.78,4a3.37,3.37,0,0,1-3.26,4l-17.11.24a3.37,3.37,0,0,1-3.3-2.47h0a3.37,3.37,0,0,1,2.39-4.16L174.45,149A3.39,3.39,0,0,1,178.62,151.64Z" className={styles.covIconLayer5} />
            <path d="M167.53,106.77a8.53,8.53,0,0,0-7.64-4.73H90.11a8.53,8.53,0,0,0-7.64,4.73L70.54,133.61H179.46Z" className={styles.covIconLayer6} />
            <path d="M125,162.88H101.47a1.38,1.38,0,0,0-1.32,1.77l1.94,6.52a1.37,1.37,0,0,0,1.32,1h43.18a1.37,1.37,0,0,0,1.32-1l1.94-6.52a1.38,1.38,0,0,0-1.32-1.77Z" className={styles.covIconLayer7} />
            <polygon points="80.08 129.43 169.92 129.43 125 51.84 80.08 129.43" className={styles.covIconLayer8} />
            <rect x="120.92" y="109.01" width="8.17" height="8.17" className={styles.covIconLayer9} />
            <rect x="120.92" y="84.51" width="8.17" height="16.33" className={styles.covIconLayer10} />
            <path d="M80.08,129.43h89.84L125,51.85Zm49-12.25h-8.16V109h8.16Zm0-16.34h-8.16V84.51h8.16Z" className={styles.covIconLayer11} />
        </svg>
</div>)
};

const Breakdown_NationalCoverCov = {
    code: 'TMBreakdownCov',
    title: 'Breakdown Cover',
    name: '<strong>Road Rescue - National Cover</strong> provides:',
    pretext: '',
    term: 'Level1',
    bulletPoints: [
        '<strong>Roadside Assistance</strong><br/>Breakdown assistance at the roadside when you`re over 1 mile away from your home.',
        '<strong>Local Recovery</strong><br/>If your vehicle cannot be repaired at the roadside, we`ll transport your vehicle and its passengers to the nearest repairer.',
        '<strong>Onward Travel</strong><br/>If your car cannot be repaired at the roadside or a local garage by the end of the working day - we’ll either arrange and pay to take you and your car to any UK destination or provide the option of 1 day’s hire car or up to £100 for alternative transport/ overnight accommodation to allow you to continue your journey. Age and licence restrictions apply for the hire car benefit.<br/><br/>If your car is stolen and not recovered by the end of the working day, for you to be transported home or to your original destination.'
    ],
    paragraphs: [
        `Read the <a href="${DocUtil.getFileUrl('tya','BREAKDOWN_IPID_BASIC')}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions.`
    ],
    policyDocumentText: `You can access the <a href="${DocUtil.getFileUrl('tya','BREAKDOWN_POLICY')}" target="_blank">Policy Document</a> for full terms and conditions.`,
    imgURL: (<div className={styles.BreakdownSVGIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1} />
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2} />
            <path d="M197,124.77l-.77-4.25a3.41,3.41,0,0,0-3.34-2.84h-12l-7-16.46a9.81,9.81,0,0,0-8.8-5.72H84.89a9.8,9.8,0,0,0-8.8,5.72l-7,16.46h-12a3.41,3.41,0,0,0-3.34,2.84L53,124.77a3.46,3.46,0,0,0,2.28,3.9l2.72.9a3.47,3.47,0,0,1,1.67,5.33l-2.22,3a3.47,3.47,0,0,0-.66,2l-.17,54.72h0a3.42,3.42,0,0,0,3.41,3.43H72.61a3.42,3.42,0,0,0,3.4-3.44V181.05h98v13.66a3.42,3.42,0,0,0,3.4,3.44H190a3.43,3.43,0,0,0,3.4-3.44L193.19,140a3.47,3.47,0,0,0-.66-2l-2.22-3a3.47,3.47,0,0,1,1.67-5.33l2.72-.9A3.46,3.46,0,0,0,197,124.77Z" className={styles.covIconLayer3} />
            <path d="M71.38,151.64l-.78,4a3.37,3.37,0,0,0,3.26,4l17.11.24a3.37,3.37,0,0,0,3.3-2.47h0a3.37,3.37,0,0,0-2.39-4.16L75.55,149A3.39,3.39,0,0,0,71.38,151.64Z" className={styles.covIconLayer4} />
            <path d="M178.62,151.64l.78,4a3.37,3.37,0,0,1-3.26,4l-17.11.24a3.37,3.37,0,0,1-3.3-2.47h0a3.37,3.37,0,0,1,2.39-4.16L174.45,149A3.39,3.39,0,0,1,178.62,151.64Z" className={styles.covIconLayer5} />
            <path d="M167.53,106.77a8.53,8.53,0,0,0-7.64-4.73H90.11a8.53,8.53,0,0,0-7.64,4.73L70.54,133.61H179.46Z" className={styles.covIconLayer6} />
            <path d="M125,162.88H101.47a1.38,1.38,0,0,0-1.32,1.77l1.94,6.52a1.37,1.37,0,0,0,1.32,1h43.18a1.37,1.37,0,0,0,1.32-1l1.94-6.52a1.38,1.38,0,0,0-1.32-1.77Z" className={styles.covIconLayer7} />
            <polygon points="80.08 129.43 169.92 129.43 125 51.84 80.08 129.43" className={styles.covIconLayer8} />
            <rect x="120.92" y="109.01" width="8.17" height="8.17" className={styles.covIconLayer9} />
            <rect x="120.92" y="84.51" width="8.17" height="16.33" className={styles.covIconLayer10} />
            <path d="M80.08,129.43h89.84L125,51.85Zm49-12.25h-8.16V109h8.16Zm0-16.34h-8.16V84.51h8.16Z" className={styles.covIconLayer11} />
        </svg>
</div>)
};

const Breakdown_NationalPlusDoorstepEuropeanCov = {
    code: 'TMBreakdownCov',
    title: 'Breakdown Cover',
    name: '<strong>Road Rescue - National plus Doorstep and European</strong> provides:',
    pretext: '',
    term: 'Level3',
    bulletPoints: [
        '<strong>Road Rescue - National plus Doorstep</strong><br/>All the benefits of Road Rescue - National plus Doorstep.',
        '<strong>Roadside assistance in Europe</strong><br/>Breakdown of your car on its way to a destination abroad, or whilst abroad. Your vehicle will be fixed at the roadside, or recovered to a local garage for repair. If this is not possible, overnight accommodation or alternative transport will be provided. Limits apply.'
    ],
    paragraphs: [
        `Read the <a href="${DocUtil.getFileUrl('tya','BREAKDOWN_IPID_EURO')}" target="_blank">Insurance Product Information Document</a> to understand the main benefits / restrictions.`
    ],
    policyDocumentText: `You can access the <a href="${DocUtil.getFileUrl('tya','BREAKDOWN_POLICY')}" target="_blank">Policy Document</a> for full terms and conditions.`,
    imgURL: (<div className={styles.BreakdownSVGIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <circle cx="125" cy="125" r="125" className={styles.covIconLayer1} />
            <circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2} />
            <path d="M197,124.77l-.77-4.25a3.41,3.41,0,0,0-3.34-2.84h-12l-7-16.46a9.81,9.81,0,0,0-8.8-5.72H84.89a9.8,9.8,0,0,0-8.8,5.72l-7,16.46h-12a3.41,3.41,0,0,0-3.34,2.84L53,124.77a3.46,3.46,0,0,0,2.28,3.9l2.72.9a3.47,3.47,0,0,1,1.67,5.33l-2.22,3a3.47,3.47,0,0,0-.66,2l-.17,54.72h0a3.42,3.42,0,0,0,3.41,3.43H72.61a3.42,3.42,0,0,0,3.4-3.44V181.05h98v13.66a3.42,3.42,0,0,0,3.4,3.44H190a3.43,3.43,0,0,0,3.4-3.44L193.19,140a3.47,3.47,0,0,0-.66-2l-2.22-3a3.47,3.47,0,0,1,1.67-5.33l2.72-.9A3.46,3.46,0,0,0,197,124.77Z" className={styles.covIconLayer3} />
            <path d="M71.38,151.64l-.78,4a3.37,3.37,0,0,0,3.26,4l17.11.24a3.37,3.37,0,0,0,3.3-2.47h0a3.37,3.37,0,0,0-2.39-4.16L75.55,149A3.39,3.39,0,0,0,71.38,151.64Z" className={styles.covIconLayer4} />
            <path d="M178.62,151.64l.78,4a3.37,3.37,0,0,1-3.26,4l-17.11.24a3.37,3.37,0,0,1-3.3-2.47h0a3.37,3.37,0,0,1,2.39-4.16L174.45,149A3.39,3.39,0,0,1,178.62,151.64Z" className={styles.covIconLayer5} />
            <path d="M167.53,106.77a8.53,8.53,0,0,0-7.64-4.73H90.11a8.53,8.53,0,0,0-7.64,4.73L70.54,133.61H179.46Z" className={styles.covIconLayer6} />
            <path d="M125,162.88H101.47a1.38,1.38,0,0,0-1.32,1.77l1.94,6.52a1.37,1.37,0,0,0,1.32,1h43.18a1.37,1.37,0,0,0,1.32-1l1.94-6.52a1.38,1.38,0,0,0-1.32-1.77Z" className={styles.covIconLayer7} />
            <polygon points="80.08 129.43 169.92 129.43 125 51.84 80.08 129.43" className={styles.covIconLayer8} />
            <rect x="120.92" y="109.01" width="8.17" height="8.17" className={styles.covIconLayer9} />
            <rect x="120.92" y="84.51" width="8.17" height="16.33" className={styles.covIconLayer10} />
            <path d="M80.08,129.43h89.84L125,51.85Zm49-12.25h-8.16V109h8.16Zm0-16.34h-8.16V84.51h8.16Z" className={styles.covIconLayer11} />
        </svg>
</div>)
};

export const CoveragesList = [ DrivingAbroadCov, TrailerCov, ECCCCov, legalExpenseCov, Breakdown_NationalCoverCov ];

export const BreakdownList = [ Breakdown_NationalPlusDoorstepCov, Breakdown_NationalCoverCov, Breakdown_NationalPlusDoorstepEuropeanCov ]

export default { DrivingAbroadCov, TrailerCov, ECCCCov, legalExpenseCov }
