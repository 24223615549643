import React from 'react';
import PropTypes from 'prop-types';
import styles from './PMQuotePage_PNCD.module.scss';
import messages from './PMQuotePage.PNCD.messages';
import _ from 'lodash';
import { contextSwitcherPropTypes } from '@jutro/prop-types';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';

function PMQuotePage_PNCDSection({ data }) {
    const ncdProtectionAllowed = _.get(data, 'lobData.personalMotor.coverables.pmVehicles.children[0].ncdProtectionAllowed.value');
    const stepBackProcedureUrl = DocUtil.getFileUrl('tya','PNCD_APPENDIX');
    return (
        (ncdProtectionAllowed === true) && (
            <div className={styles.andCoverage}>
                <div id="yourBenefitsHeader" className={styles.andQuotePageSectionTitle}>
                    <h2>{messages.pncdTitle.defaultMessage}</h2>
                </div>
                <div id="pncdOptionsContainer">
                    <p className={styles.andPncdIncluded}>
                        {messages.pncdIncluded.defaultMessage}
                    </p>
                    <p>
                        <span>{messages.pncdAddon_1.defaultMessage}</span>
                        <a href={stepBackProcedureUrl} target="_blank" rel="noopener noreferrer" className={styles.andStepBack}>
                            {messages.pncdStepBack.defaultMessage}
                        </a>
                        <span>{messages.pncdAddon_2.defaultMessage}</span>
                    </p>
                    <br />
                    <p>
                        <span>{messages.pncdAddon_3.defaultMessage}</span>
                    </p>
                </div>
            </div>)
    );
}

PMQuotePage_PNCDSection.propTypes = {
    data: PropTypes.object,
};

export default PMQuotePage_PNCDSection;
