import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { withValidation, validationPropTypes, validationDefaultProps } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import VehicleImageLookupService from './VehicleImageLookupService';
import metadata from './VehicleInfoLookupComponent.metadata.json5';
import styles from './VehicleInfoLookupComponent.module.scss';

import './VehicleInfoLookupComponent.messages';

class VehicleInfoLookupComponent extends Component {
    /**
     * @memberof gw-capability-address-react.VehicleInfoLookupComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {boolean} propTypes.displayImage - flag for specifying if
     *  image of the vehicle should be displayed
     */
    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        phoneWide: PropTypes.shape({}),
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValueChange: PropTypes.func,
        onValidate: PropTypes.func,
        displayImage: PropTypes.bool,
        displayAllFields: PropTypes.bool,
        layout: PropTypes.oneOf(['normal', 'reversed', 'full', 'indent']),
        labelPosition: PropTypes.oneOf(['left', 'right', 'top']),
        showOptional: PropTypes.bool,
        authHeader: PropTypes.shape({}).isRequired,
        submitted: PropTypes.bool,
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        ...validationPropTypes,
        ...validationDefaultProps
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        displayImage: true,
        displayAllFields: true,
        labelPosition: 'left',
        layout: 'normal',
        showOptional: true,
        submitted: false,
        phoneWide: {
            labelPosition: 'top'
        },
    };

    state = {
        showLoader: false,
        readonlyOverride: false,
        stockPhoto: undefined,
        showFieldsOverride: false,
        prevReg: undefined,
        showRegHintText: false,
        showMakeField: false,
        noCarsFound: false,
        requireFieldError: ['This field is required']
    };

    componentDidMount() {
        const {
            id,
            onValidate,
            displayAllFields,
            isComponentValid,
            registerComponentValidation
        } = this.props;

        this.setState({ showFieldsOverride: displayAllFields });
    }

    componentDidUpdate(prevProps) {
        const {
            onValidate,
            id,
            isComponentValid,
            hasValidationChanged,
            value: newVehicleData
        } = this.props;

        const { value: oldVehicleData } = prevProps;
        const isSameVehicle = _.isEqual(newVehicleData.value, oldVehicleData.value);

    }

    getIsFormValid = () => {
        const { value: vehicleData } = this.props;
        return vehicleData.aspects.subtreeValid && vehicleData.aspects.valid;
    };

    getVehicleDescription = () => {
        const { value: vehicleData } = this.props;

        const vehicleYear = _.get(vehicleData, 'firstRegisteredYear', '');
        const vehicleMake = _.get(vehicleData, 'make', '');
        const vehicleEnhancedDescription = _.get(vehicleData, 'enhancedVehicleDescription', '');
        const vehicleEngineCapacity = _.get(vehicleData, 'engineCapacity', '');

        if (vehicleYear.value === undefined || vehicleMake.value === undefined || vehicleEnhancedDescription.value === undefined || vehicleEngineCapacity.value === undefined) {
            return '';
        }
        if (vehicleYear.value === "" || vehicleMake.value === "" || vehicleEnhancedDescription.value === "" || vehicleEngineCapacity.value === "") {
            return '';
        }
        return `${vehicleYear.value} ${vehicleMake.value} ${vehicleEnhancedDescription.value} ${vehicleEngineCapacity.value} CC`;
    }

    setCarYears = () => {
        const currentYear = new Date().getUTCFullYear();
        const { oldestCarYear } = config.personalAutoConfig;

        // _.range is not inclusive. Need to add -1 to get correct years
        const yearRange = _.range(currentYear, oldestCarYear - 1);

        return yearRange.map(_.toString).map((year) => ({
            code: year,
            name: year
        }));
    };

    onVehicleRegChange = (value, fieldPath) => {
        const { path, onValueChange } = this.props;
        const pattern = /^[a-zA-Z0-9\s]{0,8}$/;

        if (pattern.test(value)) {
            this.setState({ noCarsFound: false });
            onValueChange(value, `${path}.${fieldPath}`);
        }
    };

    handleChange = (value, changedPath) => {
        const { value: vehicleData } = this.props;
        if (changedPath === 'make' && vehicleData.registrationNumber.value === undefined || changedPath === 'make' && vehicleData.registrationNumber.value === '') {
            VehicleInfoLookupService.findModels(value)
                .then((modelData) => {
                    const models = modelData.map((item) => ({
                        name: item,
                        code: item
                    }))
                    this.setState({ models: models.sort((a, b) => a.name.localeCompare(b.name)) })
                })
            if (vehicleData.model.value !== '' || vehicleData.firstRegisteredYear.value !== '' || vehicleData.fuelType.value !== '' || vehicleData.transmission.value !== '' || vehicleData.selectYourCar?.value !== '') {
                vehicleData.model.value = '';
                vehicleData.firstRegisteredYear.value = '';
                vehicleData.fuelType.value = '';
                vehicleData.transmission.value = '';
            }
        }
        if (changedPath === 'model' && vehicleData.registrationNumber.value === undefined || changedPath === 'model' && vehicleData.registrationNumber.value === '') {
            VehicleInfoLookupService.findYears(vehicleData.make.value, value)
                .then((yearData) => {
                    const years = yearData.map((item) => ({
                        name: item,
                        code: item
                    }));
                    this.setState({ years: years });
                });
        }
        if (changedPath === 'firstRegisteredYear' && vehicleData.registrationNumber.value === undefined || changedPath === 'firstRegisteredYear' && vehicleData.registrationNumber.value === '') {
            const { value: vehicleData } = this.props;
            VehicleInfoLookupService.findAbiCars(this.createAbiObject(vehicleData))
                .then((abiData) => {
                    const abi = abiData.map((item) => ({
                        name: item.enhancedVehicleDescription,
                        code: parseInt(item.abiCode)
                    }));
                    this.populateFuelTypeDropdown(abiData);
                    this.populateTransmissionTypeDropdown(abiData);

                    this.setState({ abiCars: abi.sort((a, b) => a.name.localeCompare(b.name)) })
                });
        }
        if (changedPath === 'transmission' && vehicleData.registrationNumber.value === undefined || changedPath === 'transmission' && vehicleData.registrationNumber.value === '') {
            const { value: vehicleData } = this.props;
            VehicleInfoLookupService.findAbiCars(this.createAbiObject(vehicleData))
                .then((abiData) => {
                    const abi = abiData.map((item) => ({
                        name: item.enhancedVehicleDescription + ' ' + item.engineCapacity + 'CC',
                        code: parseInt(item.abiCode)
                    }));
                    this.populateFuelTypeDropdown(abiData);
                    this.setState({ abiCars: abi.sort((a, b) => a.name.localeCompare(b.name)) })
                });
        }
        const { path, onValueChange } = this.props;
        onValueChange(value, `${path}.${changedPath}`);
    };

    showRegHint = () => {
        this.setState({ showRegHintText: !this.state.showRegHintText });
    };

    showManuelVehicleFields = () => {
        const { value: vehicleData } = this.props;
        this.props.handleRegModeChanges(false);
        VehicleInfoLookupService.findMakes()
            .then((makeData) => {
                const makes = makeData.map((item) => ({
                    name: item,
                    code: item
                }));
                this.setState({
                    showMakeField: true,
                    makes: makes.sort((a, b) => a.name.localeCompare(b.name))
                });
            });
        if (vehicleData.registrationNumber.value !== undefined && vehicleData.registrationNumber.value.length > 0) {
            // reg populated populate all fields
            let vehicleDesc = vehicleData.enhancedVehicleDescription.value
            VehicleInfoLookupService.findModels(vehicleData.make.value)
                .then((modelData) => {
                    const models = modelData.map((item) => ({
                        name: item,
                        code: item
                    }));
                    this.setState({ models: models.sort((a, b) => a.name.localeCompare(b.name)) })
                });
            VehicleInfoLookupService.findYears(vehicleData.make.value, vehicleData.model.value)
                .then((yearData) => {
                    const years = yearData.map((item) => ({
                        name: item,
                        code: item
                    }));
                    this.setState({ years: years });
                });
            VehicleInfoLookupService.findAbiCars(this.createAbiObject(vehicleData))
                .then((abiData) => {
                    const abi = abiData.map((item) => ({
                        name: item.enhancedVehicleDescription,
                        code: parseInt(item.abiCode)
                    }))
                    this.populateFuelTypeDropdown(abiData)
                    this.populateTransmissionTypeDropdown(abiData)
                    var result = abi.filter(obj => {
                        return obj.name === vehicleDesc
                    })
                    vehicleData.abiCode.value = result[0].code
                    this.setState({ abiCars: abi.sort((a, b) => a.name.localeCompare(b.name)), abiCode: result[0].code })
                });
            vehicleData.registrationNumber.value = '';
            vehicleData.vin.value = '';
        }
    };

    showRegLookUp = () => {
        const { value: vehicleData } = this.props;
        this.props.handleRegModeChanges(true);
        vehicleData.registrationNumber.value = '';
        vehicleData.make.value = '';
        vehicleData.enhancedVehicleDescription.value = '';
        vehicleData.model.value = '';
        vehicleData.firstRegisteredYear.value = '';
        vehicleData.fuelType.value = '';
        vehicleData.transmission.value = '';
        vehicleData.abiCode.value = '';

        this.setState({
            showMakeField: false,
            makes: [],
            fuelType: [],
            transmission: [],
            abiCars: [],
            models: [],
            years: [],
        });
    };

    populateFuelTypeDropdown = (data) => {
        if (data.some(e => e.fuelType === 'Petrol') && data.some(e => e.fuelType !== 'Diesel')) {
            this.setState({ fuelType: [{ 'name': 'Petrol', 'code': 'Petrol' }] })
        }
        if (data.some(e => e.fuelType !== 'Petrol') && data.some(e => e.fuelType === 'Diesel')) {
            this.setState({ fuelType: [{ 'name': 'Diesel', 'code': 'Diesel' }] })
        }
        if (data.some(e => e.fuelType === 'Petrol') && data.some(e => e.fuelType === 'Diesel')) {
            this.setState({ fuelType: [{ 'name': 'Diesel', 'code': 'Diesel' }, { 'name': 'Petrol', 'code': 'Petrol' }] })
        }
    };

    populateTransmissionTypeDropdown = (data) => {
        if (data.some(e => e.transmission === 'AUTO') && data.some(e => e.transmission !== 'MAN')) {
            this.setState({ transmission: [{ 'name': 'Automatic', 'code': 'AUTO' }] })
        }
        if (data.some(e => e.transmission !== 'AUTO') && data.some(e => e.transmission === 'MAN')) {
            this.setState({ transmission: [{ 'name': 'Manual', 'code': 'MAN' }] })
        }
        if (data.some(e => e.transmission === 'AUTO') && data.some(e => e.transmission === 'MAN')) {
            this.setState({ transmission: [{ 'name': 'Manual', 'code': 'MAN' }, { 'name': 'Automatic', 'code': 'AUTO' }] })
        }
    };

    createAbiObject = (data) => {
        let abiDataObject;
        if (data.transmission.value === '' && data.fuelType.value !== '') {
            return abiDataObject = { 'make': data.make.value, 'model': data.model.value, 'firstRegisteredYear': data.firstRegisteredYear.value, 'fuelType': data.fuelType.value }
        }
        if (data.transmission.value !== '' && data.fuelType.value === '') {
            return abiDataObject = { 'make': data.make.value, 'model': data.model.value, 'firstRegisteredYear': data.firstRegisteredYear.value, 'transmission': data.transmission.value }
        }
        if (data.transmission.value === '' && data.fuelType.value === '') {
            return abiDataObject = { 'make': data.make.value, 'model': data.model.value, 'firstRegisteredYear': data.firstRegisteredYear.value }
        }
        if (data.transmission.value !== '' && data.fuelType.value !== '') {
            return abiDataObject = { 'make': data.make.value, 'model': data.model.value, 'firstRegisteredYear': data.firstRegisteredYear.value, 'transmission': data.transmission.value, 'fuelType': data.fuelType.value }
        }
    };

    onSearchVehicle = () => {
        const { value: vehicleData, displayAllFields, authHeader } = this.props;
        const { prevReg } = this.state;
        const RegNumber = _.get(vehicleData, 'registrationNumber.value');

        if (RegNumber === undefined) {
            this.setState({ showFieldsOverride: displayAllFields });
            return;
        }

        this.setState({ showLoader: true, showRegHintText: false });

        VehicleInfoLookupService.lookupVehicleByRegNumber(RegNumber, authHeader)
            .then((vehicleInfo) => {
                const foundVehicle = _.get(vehicleInfo, 'errorCode') === undefined;
                const vehicleMake = _.get(vehicleInfo, 'make', '');
                const vehicleModel = _.get(vehicleInfo, 'model', '');
                const vehicleYear = _.get(vehicleInfo, 'firstRegisteredYear', '');
                const vehicleABICode = _.get(vehicleInfo, 'abicode', '');
                const vehicleEnhancedDescription = _.get(vehicleInfo, 'enhancedVehicleDescription', '');
                const vehicleBodyType = _.get(vehicleInfo, 'bodyType', '');
                const vehicleColour = _.get(vehicleInfo, 'colour', '');
                const vehicleDescription = _.get(vehicleInfo, 'description', '');
                const vehicleEngineCapacity = _.get(vehicleInfo, 'engineCapacity', '');
                const vehicleFirstRegisteredMonth = _.get(vehicleInfo, 'firstRegisteredMonth', '');
                const vehicleFuelType = _.get(vehicleInfo, 'fuelType', '');
                const vehicleNumberOfDoors = _.get(vehicleInfo, 'numberOfDoors', '');
                const vehicleTransmission = _.get(vehicleInfo, 'transmission', '');
                const vehicleVin = _.get(vehicleInfo, 'vin', '');

                let stockPhoto;
                if (!_.get(vehicleInfo, 'vin', false)) {
                    this.setState({ noCarsFound: true });
                }
                if (_.get(vehicleInfo, 'vin', false)) {
                    this.setState({ noCarsFound: false });
                }

                if (foundVehicle) {
                    /* stockPhoto = VehicleImageLookupService.lookupPhoto(
                        vehicleYear,
                        vehicleMake,
                        vehicleModel
                    ); */

                    if (!displayAllFields) {
                        this.setState({ showFieldsOverride: true });
                    }
                } else if (!displayAllFields) {
                    this.setState({ showFieldsOverride: false });
                }

                this.handleChange(vehicleMake, 'make');
                this.handleChange(vehicleModel, 'model');
                this.handleChange(vehicleYear, 'firstRegisteredYear');
                this.handleChange(vehicleABICode, 'abicode');
                this.handleChange(vehicleEnhancedDescription, 'enhancedVehicleDescription');
                this.handleChange(vehicleBodyType, 'bodyType');
                this.handleChange(vehicleColour, 'colour');
                this.handleChange(vehicleDescription, 'description');
                this.handleChange(vehicleEngineCapacity, 'engineCapacity');
                this.handleChange(vehicleFirstRegisteredMonth, 'firstRegisteredMonth');
                this.handleChange(vehicleFuelType, 'fuelType');
                this.handleChange(vehicleNumberOfDoors, 'numberOfDoors');
                this.handleChange(vehicleTransmission, 'transmission');
                this.handleChange(vehicleVin, 'vin');

                this.setState({ showLoader: false, readonlyOverride: foundVehicle, stockPhoto, prevReg: RegNumber });
            })
            .catch(() => {
                this.setState({ showLoader: false });
            });
    };

    render() {
        const {
            showLoader, readonlyOverride, stockPhoto, showFieldsOverride, noCarsFound, requireFieldError
        } = this.state;
        const {
            value: vehicleData,
            displayImage,
            phoneWide,
            layout,
            labelPosition,
            showOptional,
            submitted
        } = this.props;

        const overrideProps = {
            '@field': {
                showOptional: showOptional,
                layout: layout,
                labelPosition: labelPosition,
                phoneWide
            },
            addresslookupSearch: {
                visible: !this.state.showMakeField
            },
            vehicleReg: {
                onValueChange: this.onVehicleRegChange,
                validationMessages: (vehicleData.registrationNumber.value == '' || vehicleData.registrationNumber.value == null ) ? requireFieldError : (noCarsFound ? ['We could not find this car. You must either provide your registration number or enter your car details manually'] : [])
            },
            donotknowinformation: {
                visible: !this.state.showMakeField
            },
            donotknowinformationlink: {
                visible: !this.state.showMakeField
            },
            showreglookuplink: {
                visible: this.state.showMakeField
            },
            RegInfoContainer: {
                visible: !showFieldsOverride
            },
            regInfoTextContent: {
                visible: this.state.showRegHintText
            },
            vehicleDescription: {
                readOnly: true,
                visible: !this.state.showMakeField,
                value: this.getVehicleDescription()
            },
            vehicleMake: {
                visible: this.state.showMakeField,
                availableValues: this.state.makes,
                showErrors: !vehicleData.make.value && submitted,
                validationMessages: !vehicleData.make.value && submitted ? requireFieldError : []
            },
            vehicleModel: {
                visible: this.state.showMakeField,
                availableValues: this.state.models,
                showErrors: !vehicleData.model.value && submitted,
                // eslint-disable-next-line max-len
                validationMessages: !vehicleData.model.value && submitted ? requireFieldError : []

            },
            vehicleYear: {
                visible: this.state.showMakeField,
                availableValues: this.state.years,
                showErrors: !vehicleData.firstRegisteredYear.value && submitted,
                validationMessages: !vehicleData.firstRegisteredYear.value && submitted ? requireFieldError : []

            },
            vehicleFuelType: {
                visible: this.state.showMakeField,
                availableValues: this.state.fuelType,
                showErrors: !vehicleData.fuelType.value && submitted,
                validationMessages: !vehicleData.fuelType.value && submitted ? requireFieldError : []

            },
            vehicleTransmission: {
                visible: this.state.showMakeField,
                availableValues: this.state.transmission,
                showErrors: !vehicleData.transmission.value && submitted,
                validationMessages: !vehicleData.transmission.value && submitted ? requireFieldError : []

            },
            abiCode: {
                readOnly: !this.state.showMakeField ? readonlyOverride : false,
                visible: this.state.showMakeField,
                availableValues: this.state.abiCars,
                showErrors: vehicleData.abiCode.value === undefined && submitted,
                validationMessages: (vehicleData.abiCode.value == null || vehicleData.abiCode.value == '') && submitted ? requireFieldError : []

            },
            imageContainer: {
                visible: displayImage && stockPhoto !== undefined,
                className: classNames(styles.imageContainer, {
                    digitalFieldComponentAliginLeft: labelPosition === 'left'
                })
            },
            imageComponent: {
                src: stockPhoto
            }
        };
        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onSearchVehicle: this.onSearchVehicle,
                showRegHint: this.showRegHint,
                showManuelVehicleFields: this.showManuelVehicleFields,
                showRegLookUp: this.showRegLookUp
            }
        };
        return (
            <>
                {showLoader ? <div className="loader"></div> : <></>}

                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={vehicleData}
                    overrideProps={overrideProps}
                    onValueChange={this.handleChange}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    showErrors={submitted}
                />
            </>
        );
    }
}

export default withViewModelService(
    withAuthenticationContext(VehicleInfoLookupComponent)
);
