import { defineMessages } from 'react-intl';

export default defineMessages({
    SessionEnded: {
        id: 'quoteandbind.views.session-ended.Purchased Failed',
        defaultMessage: 'Purchased Failed',
    },
    SessionEndedWarning: {
        id: 'quoteandbind.views.session-ended.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    SessionEndedSolution: {
        id: 'quoteandbind.views.session-ended.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.session-ended.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.session-ended.Reference Number',
        defaultMessage: 'Reference Number',
    },
});
