import React from 'react';
import classNames from 'classnames';
import styles from './PMQuotePage_Benefits.module.scss';
import messages from './PMQuotePage_Benefits.messages';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';

const tyaBenefits = [
    messages.toyLexParts.defaultMessage,
    messages.keyProtect.defaultMessage,
    messages.courtesyCar.defaultMessage,
    messages.uninsuredDriver.defaultMessage,
    messages.vandalismCover.defaultMessage,
    messages.drivingAbroad.defaultMessage,
    messages.ncdProtection.defaultMessage,
];

let benefitsList = [];

tyaBenefits.forEach((benefit, index) => {
    benefitsList.push(<li key={index}>{benefit}</li>);
});

function PMQuotePage_BenefitsSection() {
    const insuranceProductInfoUrl = DocUtil.getFileUrl('tya','CORE_IPID');
    return (
        <div id="benefitsSectionContainer">
            <div id="yourBenefitsHeader" className={styles.andQuotePageSectionTitle}>
                <h2>{messages.benefitsTitle.defaultMessage}</h2>
            </div>
            <div className={styles.andBenefitsLabelList}>
                <ul>{benefitsList}</ul>
            </div>
            <div>
                <p>{messages.benefitsNote_Applies.defaultMessage}</p>
                <p>{messages.benefitsNote_1.defaultMessage}</p>
                <div className={styles.benefitsNote_2}>{messages.benefitsNote_2.defaultMessage}</div>
                <br />
                <p>
                    <span>{messages.benefitsNote_IPID_preLink.defaultMessage}</span>
                    <a href={insuranceProductInfoUrl} target="_blank" rel="noopener noreferrer" className={styles.andQuoteProductInformationDocumentLink}>
                        {messages.benefitsNote_IPID_link.defaultMessage}
                    </a>
                    <span>{messages.benefitsNote_IPID_postLink.defaultMessage}</span>
                </p>
                <br />
            </div>
        </div>
    );
}

export default PMQuotePage_BenefitsSection;
