import { defineMessages } from 'react-intl';

export default defineMessages({
    benefitsTitle: {
        id: 'quoteandbind.pm.components.benefits.Title',
        defaultMessage: 'Your policy includes these excellent benefits as standard:',
    },
    toyLexParts: {
        id: 'quoteandbind.pm.components.benefits.toy lex parts',
        defaultMessage: 'Genuine Toyota / Lexus Parts+ by Toyota Insurance Services approved repairers, protecting any existing warranties.',
    },
    keyProtect: {
        id: 'quoteandbind.pm.components.benefits.Key Protect',
        defaultMessage: 'Up to £1,500 cover for loss and theft of keys',
    },
    courtesyCar: {
        id: 'quoteandbind.pm.components.benefits.Courtesy Car',
        defaultMessage: 'Courtesy car insurance cover*',
    },
    uninsuredDriver: {
        id: 'quoteandbind.pm.components.benefits.Uninsured Driver',
        defaultMessage: 'Uninsured Driver Benefit cover**',
    },
    vandalismCover: {
        id: 'quoteandbind.pm.components.benefits.Vandalism Cover',
        defaultMessage: "Vandalism Promise - If you make a claim for malicious damage, you won't lose your NCD",
    },
    drivingAbroad: {
        id: 'quoteandbind.pm.components.benefits.Driving Abroad',
        defaultMessage: '3 day EU comprehensive driving cover, with the option to extend to 90 days for an additional premium',
    },
    ncdProtection: {
        id: 'quoteandbind.pm.components.benefits.NCD Protection',
        defaultMessage: "Have more than 2 years No Claim Discount (NCD)? - We'll protect your NCD for free!",
    },
    benefitsNote_Applies: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.applies',
        defaultMessage: "+ Applies to Toyota / Lexus cars only. Excludes windscreen / glass replacement",
    },
    benefitsNote_1: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.1',
        defaultMessage: "*If you make a claim for malicious damage, you won't lose your NCD",
    },
    benefitsNote_2: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.2',
        defaultMessage: "**if you make a claim for an accident that is not your fault, and the driver of the other vehicle that hits you is not insured, you won't lose your NCD, or have to pay an excess",
    },
    benefitsNote_IPID_preLink: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.preLink',
        defaultMessage: 'Please read the ',
    },
    benefitsNote_IPID_link: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.link',
        defaultMessage: 'Insurance Product Information Document',
    },
    benefitsNote_IPID_postLink: {
        id: 'quoteandbind.pm.components.benefits.Benefit note.IPID.postLink',
        defaultMessage: ' to understand the benefits, limitations and exclusions of our cover.',
    },
});
