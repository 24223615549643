import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import warning from 'warning';
import { ServiceManager } from '@jutro/services';
import checkSegmentationOption from './CheckSegmentationOption';
import { AB_EXPERIMENT_SEGMENT_SERVICE_ID } from './ABExperimentSegmentation';
import { QUERY_PARAMS_SEGMENT_SERVICE_ID } from './QueryParamsSegmentation';
import { LOCATION_SEGMENT_SERVICE_ID } from './LocationSegmentation';
//import { PRODUCT_SEGMENT_SERVICE_ID } from './ProductSegmentation';

export default class Segmentation extends Component {
    static propTypes = {
        params: PropTypes.shape({
            providerID: PropTypes.string,
            experimentID: PropTypes.string,
            compareBy: PropTypes.string
        }),
        type: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired
    };

    static defaultProps = {
        params: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            variant: null
        };

        this.experiments = {
            'ab-experiment': AB_EXPERIMENT_SEGMENT_SERVICE_ID,
            'query-params': QUERY_PARAMS_SEGMENT_SERVICE_ID,
            campaign: QUERY_PARAMS_SEGMENT_SERVICE_ID,
            location: LOCATION_SEGMENT_SERVICE_ID//,
            //product: PRODUCT_SEGMENT_SERVICE_ID
        };
    }

    componentDidMount() {
        const { type, params } = this.props;
        const Provider = ServiceManager.getService(this.experiments[type]);

        Provider.retrieveExperimentVariant(params).then((value) => {
            this.setState({ variant: value });
        });
    }

    getMatchedOptions() {
        const { children } = this.props;
        const { variant } = this.state;

        const matchedOptions = React.Children.toArray(children)
            .filter(({ props }) => {
                return checkSegmentationOption(props.is, variant);
            });
        return _.isEmpty(matchedOptions) ? null : matchedOptions;
    }

    render() {
        const { children } = this.props;
        const { variant } = this.state;

        if (variant === null) { return null; }

        const defaultOptions = React.Children.toArray(children)
            .filter(({ props }) => props.defaultOption);

        warning(React.Children.count(defaultOptions) <= 1, 'More than one Segmentaion defaultOption supplied');

        return this.getMatchedOptions() || _.head(defaultOptions) || null;
    }
}
