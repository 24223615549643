/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { Submission } from 'gw-capability-quoteandbind';

import metadata from './PMQuoteReviewComponent.metadata.json5';
import './PMQuoteReviewComponent.messages';
import styles from '../Stylesheets/PMQuoteReview.module.scss';

function formatDate(value) {
    return (`0${value}`).slice(-2);
}

function PMQuoteReviewComponent(props) {
    const {
        data: submissionVM,
        readOnlyFields,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        onValueChange,
        showOptional,
        phoneWide,
        navigateToDetails,
        onBreakDownCoverClick,
        onTrailerCoverClick,
        onDrivingAbroadCoverClick,
        onLegalExpensesCoverClick,
        onEnhancedCourtesyCarCoverClick
    } = props;
    const translator = useTranslator();
    const drivers = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.children');
    const driversPM = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers');
    const accountHolderVM = drivers.find((driver) => driver.value.isPolicyHolder);
    const accountHolder = accountHolderVM.value;
    const policyAddress = _.get(submissionVM, 'baseData.policyAddress.value');
    const [policyAddressLine1, setPolicyAddressLine1] = useState(_.get(policyAddress, 'addressLine1'));
    const vehicleVM = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0]');
    const vehicle = vehicleVM.value;
    const doB = _.get(accountHolder, 'dateOfBirth');

    useEffect(() => {
        if (policyAddress.organisationName_itb) {
            setPolicyAddressLine1(`${policyAddress.organisationName_itb} ${policyAddressLine1}`);
        }
        if (policyAddress.departmentName_itb) {
            setPolicyAddressLine1(`${policyAddress.departmentName_itb} ${policyAddressLine1}`);
        }
    }, []);

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const periodStartDateNoFormat = _.get(submissionVM.value, 'baseData.periodStartDate');
    const periodEndDateNoFormat = _.get(submissionVM.value, 'baseData.periodEndDate');

    const originalPeriodEndDate = new Date(`${periodEndDateNoFormat.year}-${periodEndDateNoFormat.month + 1}-${periodEndDateNoFormat.day}`);
    const updatedPeriodEndDate = new Date(originalPeriodEndDate);
    updatedPeriodEndDate.setDate(originalPeriodEndDate.getDate() - 1);

    const periodStartDate = `${formatDate(periodStartDateNoFormat.day)}-${formatDate(periodStartDateNoFormat.month + 1)}-${periodStartDateNoFormat.year} 00:00:00`;
    const periodEndDate = `${formatDate(updatedPeriodEndDate.getDate())}-${formatDate(updatedPeriodEndDate.getMonth() + 1)}-${updatedPeriodEndDate.getFullYear()} 23:59:59`;
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const displayName = (accountHolder) => {
        let availableValues = _.get(accountHolderVM, 'title_itb.aspects.availableValues');
        let title = availableValues.find((item) => item.code === accountHolder.title_itb);

        if (title) {
            return `${translator({
                id: title.name,
                defaultMessage: title.name
            })} ${accountHolder.firstName} ${accountHolder.lastName}`;
        } else {
            return `${accountHolder.firstName} ${accountHolder.lastName}`;
        }
    };

    const getOccupation = (accountHolder) => {
        let availableValues = _.get(accountHolderVM, 'occupation_itb.aspects.availableValues');
        let occupation = availableValues.find((item) => item.code === accountHolder.occupation_itb);
        return occupation ? translator({
            id: occupation.name,
            defaultMessage: occupation.name
        }) : '';
    };

    const howYouUseYourCar = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'classOfUse.aspects.availableValues');
        let UseofYourCar = availableValues.find((item) => item.code === vehicle.classOfUse);
        return UseofYourCar ? translator({
            id: UseofYourCar.name,
            defaultMessage: UseofYourCar.name
        }) : '';
    };

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const formatPolicyDates = (policyDates) => {
        if (policyDates.includes(' ')) {
            const dateArray = policyDates.split(' ');

            return dateArray[0];
        } else {
            return dateTime;
        }
    };

    const vehicleDetailsFormat = (vehicleDetails) => {
        let strDetailsOutput = '';
        const strDelimiter = ' ';

        if (vehicleDetails.registrationNumber && vehicleDetails.make && vehicleDetails.model) {
            strDetailsOutput = vehicleDetails.registrationNumber + strDelimiter + vehicleDetails.make + strDelimiter + vehicleDetails.model;
        }

        return strDetailsOutput;
    };

    const getAddress = () => {
        let addressLine1Value = _.get(policyAddress, 'addressLine1') ? policyAddressLine1: '';

        if(addressLine1Value) {
            let addressLine2Value = policyAddress.addressLine2 ? policyAddress.addressLine2 : '';
            let addressLine3Value = policyAddress.addressLine3 ? policyAddress.addressLine3 : '';
            let cityValue = policyAddress.city ? policyAddress.city : '';
            let countyValue = policyAddress.county ? policyAddress.county : '';
            let postalCodeValue = policyAddress.postalCode ? policyAddress.postalCode : '';

            return `${addressLine1Value} \n ${addressLine2Value} \n ${addressLine3Value} \n ${cityValue} \n ${countyValue} \n ${postalCodeValue}`;
        }
        else {
            return policyAddress.displayName ? policyAddress.displayName : '';
        }
    };

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const generateOverrides = useCallback(() => {
        const driversData = _.get(submissionVM, 'lobData.personalMotor.coverables.pmDrivers.children');
        const mainDriver = driversData.find((driver) => driver.value.mainDriver === true);
        const otherDrivers = driversData.filter(driver => driver.value.mainDriver !== true);
        const drvrs = mainDriver ? [mainDriver, ...otherDrivers] : otherDrivers;
        const overrideProps = {};
        drvrs.forEach((driver, index) => {
            overrideProps[`PMQuoteReviewDriverComponent${index}`] = {
                data: driver,
            };
        });
        return overrideProps;
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
            readOnly: true
        },
        [`pmQuoteReviewAboutYouAccordionCard${index}`]: {
            title: (
                <div className={styles.accordionTitleContainer}>
                    <div className={styles.accordionTitleContainerText1}>About you</div>
                    <div className={styles.accordionTitleContainerText2}>{displayName(accountHolder)}</div>
                    <div className={styles.accordionTitleContainerText3}>Change / View</div>
                </div>
            ),
        },
        [`pmQuoteReviewYourCoverAccordionCard${index}`]: {
            title: (
                <div className={styles.accordionTitleContainer}>
                    <div className={styles.accordionTitleContainerText1}>Your cover</div>
                    <div className={styles.accordionTitleContainerText2}>Comprehensive cover starts {periodStartDate}</div>
                    <div className={styles.accordionTitleContainerText3}>Change / View</div>
                </div>
            ),
        },
        [`quoteReviewDriverAccordionCard${index}`]: {
            title: (
                <div className={styles.accordionTitleContainer}>
                    <div className={styles.accordionTitleContainerText1}>Drivers</div>
                    <div className={styles.accordionTitleContainerText2}>{driversPM.length} {driversPM.length > 1 ? 'Drivers' : 'Driver' }</div>
                    <div className={styles.accordionTitleContainerText3}>Change / View</div>
                </div>
            ),
        },
        [`quoteReviewCarAccordionCard${index}`]: {
            title: (
                <div className={styles.accordionTitleContainer}>
                    <div className={styles.accordionTitleContainerText1}>Your car</div>
                    <div className={styles.accordionTitleContainerText2}>{vehicleDetailsFormat(vehicle)}</div>
                    <div className={styles.accordionTitleContainerText3}>Change / View</div>
                </div>
            ),
        },
        [`quoteReviewAddOnsAccordionCard${index}`]: {
            title: (
                <div className={styles.accordionTitleContainer}>
                    <div>Add-ons</div>
                    <div> </div>
                    <div>Change / View</div>
                </div>
            ),
        },
        [`dateOfBirthAboutYou${index}`]: {
            value: _.get(accountHolder, 'dateOfBirth') ? `${formatDate(doB.day)}-${formatDate(doB.month + 1)}-${doB.year}` : ''
        },
        [`policyHolderAboutYou${index}`]: {
            value: displayName(accountHolder)
        },
        [`occupationAboutYou${index}`]: {
            value: getOccupation(accountHolder)
        },
        [`addressAboutYou${index}`]: {
            value: getAddress()
        },
        [`homeownerAboutYou${index}`]: {
            value: _.get(accountHolder, 'homeOwner_itb') ? 'Yes' : 'No'
        },
        [`PMQuoteReviewCarComponent${index}`]: {
            data: submissionVM,
            navigateToDetails: navigateToDetails
        },
        [`coverStartYourCover${index}`]: {
            value: periodStartDate
        },
        [`coverEndYourCover${index}`]: {
            value: periodEndDate
        },
        [`coverLevelYourCover${index}`]: {
            value: 'Comprehensive'
        },
        [`howUseCarYourCover${index}`]: {
            value: howYouUseYourCar(vehicle)
        },
        [`PMQuoteReviewAddOnsComponent${index}`]: {
            data: submissionVM,
            onBreakDownCoverClick: onBreakDownCoverClick || (() => { }),
            onTrailerCoverClick: onTrailerCoverClick || (() => { }),
            onDrivingAbroadCoverClick: onDrivingAbroadCoverClick || (() => { }),
            onLegalExpensesCoverClick: onLegalExpensesCoverClick || (() => { }),
            onEnhancedCourtesyCarCoverClick: onEnhancedCourtesyCarCoverClick || (() => { })
        },
        [`amendDetailsDriver${index}`]: {
            onClick: () => {
                if (navigateToDetails) {
                    navigateToDetails('driver-summary');
                }
            }
        },
        [`amendDetailsAboutYou${index}`]: {
            onClick: () => {
                if (navigateToDetails) {
                    navigateToDetails('about-you');
                }
            }
        },
        [`amendDetailsYourCover${index}`]: {
            onClick: () => {
                if (navigateToDetails) {
                    navigateToDetails('about-you'); // TODO: change to your-cover page when we create one /OR/ onclick for popup
                }
            }
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };
    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMQuoteReviewComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMQuoteReviewComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMQuoteReviewComponent;
