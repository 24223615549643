import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ModalBody, ModalFooter, ModalHeader, ModalNext } from '@jutro/components';
import config from 'app-config';

const sessionEnabledSteps = [
    'about-you',
    'driver-details',
    'driver-summary',
    'vehicle-details',
    'quote',
    'legal-and-info-1',
    'legal-and-info-2',
    'legal-and-info-2-monthly',
    'legal-and-info-3',
    'payment'
];

const SessionService = () => {
    const history = useHistory();
    const IDLE_TIMEOUT = config.sessionConfig.inactivityIntervalMins * 1000 * 60;
    const SESSION_TIMEOUT = config.sessionConfig.sessionIntervalMins * 1000 * 60;
    const [showIdlePopup, setShowIdlePopup] = useState(false);

    let idleInterval, sessionInterval;

    const showIdlePopupRef = useRef(showIdlePopup);

    
    const isSessionEnabled = (stepPath) => {
        const stepName = stepPath.replaceAll('/','').replace('quoteandbuy','');
        return sessionEnabledSteps.includes(stepName);
    };

    const resetTimers = () => {
        setShowIdlePopup(false);
        showIdlePopupRef.current = false;
        clearTimeout(idleInterval);
        clearTimeout(sessionInterval);
    };

    const resetIdleTimerOnly = () => {
        clearTimeout(idleInterval);
    };

    const startIdleTimer = () => {
        clearTimeout(idleInterval);
        idleInterval = setTimeout(() => {
            if(isSessionEnabled(window.location.pathname)) {
                setShowIdlePopup(true);
                showIdlePopupRef.current = true;
            }
            else {
                resetTimers();
            }
        }, IDLE_TIMEOUT);
    };

    const startSessionTimer = () => {
        clearTimeout(sessionInterval);
        sessionInterval = setTimeout(() => {
            if(isSessionEnabled(window.location.pathname)) {
                sessionEnded();
            }
            else {
                resetTimers();
            }
        }, SESSION_TIMEOUT);
    };

    const sessionEnded = useCallback(() => {
        window.sessionStorage.clear();
        resetTimers();
        history.push({
            pathname: '/timeout',
        });
    }, [history]);

    const handleMouseMove = () => {
        if(!isSessionEnabled(window.location.pathname)) {
            resetTimers();
        }
        else if( !showIdlePopupRef.current) {
            if(!showIdlePopup) {
                startIdleTimer();
                startSessionTimer();
            }
        }
    };

    const handleKeyDown = () => {
        if(!isSessionEnabled(window.location.pathname)) {
            resetTimers();
        }
        else if( !showIdlePopupRef.current) {
            if(!showIdlePopup) {
                startIdleTimer();
                startSessionTimer();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            clearTimeout(idleInterval);
            clearTimeout(sessionInterval);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleReturnToQuote = () => {
        setShowIdlePopup(false);
        showIdlePopupRef.current = false;
        startIdleTimer();
    };

    return (
    <>
        <ModalNext isOpen={showIdlePopup}>
            <ModalHeader onClose={handleReturnToQuote} title='Are you still there?'/>
            <ModalBody>
                <p>We're just checking you are still working on your quote? This page will timeout if there is no activity in the next 30 minutes.</p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleReturnToQuote}>Return to Quote</Button>
            </ModalFooter>
        </ModalNext>
    </>
    );
};

export default SessionService;