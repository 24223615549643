import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { LocaleService } from 'gw-portals-i18n-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import App from './app/App';
import TYAThemeConfig from '@jutro/theme-styles/TYA/config';

const { trackingConfig = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (window.self === window.top) {
    const theBody = document.getElementsByTagName('body')[0];
    theBody.style.display = 'block';
} else {
    window.top.location = window.self.location;
}

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLanguageOrLocaleChange: true,
    themeConfig: TYAThemeConfig,
    onInit: () => {
        LocaleService.register();
    },
    appName: {
        id: 'digital.appName',
        defaultMessage: 'Toyota Insurance Services'
    },
    appDescription: 'Toyota Insurance Services Quote and Buy'
});
