/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState, useMemo
} from 'react';
import _, { add, set } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ScrollToError } from '@jutro/wizard-next';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { ErrorBoundary } from 'gw-portals-error-react';
import { withRouter } from 'react-router-dom';
import { MockUpUtil, ScrollToElementUtil } from 'gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import classNames from 'classnames';
import CookiesModal from 'gw-capability-policyjob-react/components/CookiesComponent/CookiesModal';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';
import CoverageUtil from '../../util/CoverageUtil';
import ValidationUtil from '../../util/ValidationUtil';
import wizardConfig from '../../config/pm-wizard-config.json5';

import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';

import metadata from './QuotePage.metadata.json5';

const PATH_TO_MOCK_DATA = 'quote.pm';
const MOCK_DATA_TO_SET = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

const ANNUAL_PAYMENT_LEGAL_PAGES = ['legal-and-info-1', 'legal-and-info-2', 'payment', 'success-payment'];
const MONTHLY_PAYMENT_LEGAL_PAGES = ['legal-and-info-1', 'legal-and-info-2-monthly', 'legal-and-info-3', 'payment', 'success-payment'];

const CORRECT_COVERAGE_ORDER_TYA = ['PMLegalExpensesCov', 'TMBreakdownCov', 'TMEnhancedCourtesyCarCov', 'PMDrivingAbroadCov', 'PMTrailerCov'];


function structureClauseTableData(coverage, index = '') {
    // putting ID into an object as the Jutro table component expects an object
    return {
        coverageUniqueID: `${coverage.publicID}${index}`
    };
}

function loadArrayFromSessionStorage(key) {
    const item = window.sessionStorage.getItem(key);
    return item != null ? JSON.parse(item) : [];
  }

function generateClauseData({ columnData, completeCoveragePath }) {
    return columnData.map(({ lob, code }) => {
        return {
            code: code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: _.get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (submissionVM, affectedQuote, clauses, volExcess, pncd) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses,
        voluntaryExcess: volExcess,
        ncdProtection: pncd
    };
};

const getCoveragesUniqueID = (submissionVM) => {
    const offerings = _.get(submissionVM, 'lobData.personalMotor.offerings.value');
    /* const pmLineCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .pmLineCoverages.map((coverage) => structureClauseTableData(coverage))),
        'coverageUniqueID'
    ); */
    const pmLineCoverages = [];
    const pmVehicleCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .pmVehicleCoverages.flatMap(({ coverages }, index) => coverages
                .map((coverage) => structureClauseTableData(coverage, `__${index}`)))),
        'coverageUniqueID'
    );
    return {
        pmLineCoverages,
        pmVehicleCoverages
    };
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalMotor.offerings.value';
    const quoteOfferingPath = 'quoteData.offeredQuotes.value';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ code, quote }) => code !== 'CUSTOM' && !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, quotePath, volExcess, filterChangedClauses = false) => {
    const lobOffering = _.get(vm.value, `${quotePath}.lobs`);
    const quoteOffering = _.get(vm.value, `${quotePath}`);
    const ncdYears = _.get(vm, 'lobData.personalMotor.coverables.pmVehicles.children[0].ncdYears.value');
    const ncdProtectionValue = ncdYears >= 2;
    return structureCustomQuote(vm, quoteOffering, lobOffering, volExcess, ncdProtectionValue);
};

const generateTableData = (submissionVM, columnData, translator) => {
    const coveragesUniqIDs = getCoveragesUniqueID(submissionVM);

    const pmLineCoverages = {
        header: translator(messages.generalCoverages),
        data: coveragesUniqIDs.pmLineCoverages,
        tableContent: generateClauseData({
            columnData,
            completeCoveragePath: 'coverages.pmLineCoverages'
        })
    };

    const pmVehicleCoverages = _.flatMap(columnData, 'lob.data.coverages.pmVehicleCoverages', []);
    const coveragesByVehicle = _.keyBy(pmVehicleCoverages, 'vehicleName');

    const vehiclesMetadata = Object.entries(coveragesByVehicle).map((
        [vehicleName],
        vehicleIndex
    ) => {
        const completeCoveragePath = `coverages.pmVehicleCoverages.children[${vehicleIndex}].coverages`;
        return {
            header: `${translator(messages.vehicleSpecificCoverage)} ${vehicleName}`,
            data: coveragesUniqIDs.pmVehicleCoverages.filter(
                (coverage) => coverage.coverageUniqueID.endsWith(`__${vehicleIndex}`)
            ),
            tableContent: generateClauseData({ columnData, completeCoveragePath })
        };
    });

    return [pmLineCoverages, ...vehiclesMetadata];
};

const getCoverage = (patternCode, offering) => {
    return offering.value.lobs.personalMotor.pmVehicleCoverages[0].coverages.find((cov) => cov.codeIdentifier === patternCode);
};

const getCoverageTermFromCoverage = (coverage, covTermPatternCode) => {
    return coverage.terms.find((term) => term.patternCode === covTermPatternCode);
};

const getCoveragesArray = (submission) => {
    const coverages = _.get(submission.value, 'lobData.personalMotor.offerings[0].coverages.pmVehicleCoverages[0].coverages');
    const brandSpecificCov = CoverageUtil.getBrandSpecificCoverages('tya');
    return CoverageUtil.getCurrentCoveragesRefined(coverages, brandSpecificCov);
};

const tyaCovsCorrectOrderSort = (a, b) => {
    return CORRECT_COVERAGE_ORDER_TYA.indexOf(a.codeIdentifier) - CORRECT_COVERAGE_ORDER_TYA.indexOf(b.codeIdentifier);
};

const setBrandSpecificCoverages = (coverages) => {
    const tyaCoverages = [];
    const brandSpecificCoverages = CoverageUtil.getBrandSpecificCoverages('tya');
    coverages.forEach((cov) => {
        for (const brandCov in brandSpecificCoverages) {
            if (brandSpecificCoverages[brandCov].CoverageCode === cov.codeIdentifier) {
                tyaCoverages.push(cov);
            }
        }
    });
    tyaCoverages.sort(tyaCovsCorrectOrderSort);
    return tyaCoverages;
};

function QuotePage(props) {
    const {
        wizardSnapshot, wizardData: submissionVM, updateWizardData, steps, jumpTo, changeNextSteps, clearVisitedStepsAfterCurrent, currentStep, history
    } = props;
    const { steps: allSteps } = wizardConfig;
    const quoteID = _.get(submissionVM, 'quoteID.value');
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { authHeader } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const { onValidate, disregardFieldValidation } = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const isAggsQuote = submissionVM.value.isAggsQuoted_AND() ? submissionVM.value.isAggsQuoted_AND() : false;
    const [updateInFlight, setUpdateInFlight] = useState(false);
    const [unSelectedCount, setUnSelectedCount] = useState(5);
    const [submitted, setSubmitted] = useState(false);
    const [isHomeNumberValid, setIsHomeNumberValid] = useState(ValidationUtil.isHomeNumberValid(submissionVM));
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(ValidationUtil.isPhoneNumberValid(submissionVM));
    const isPhoneNumberLnPValid = useState(ValidationUtil.isPhoneNumberLnPValid(submissionVM));
    const invalidPropsLength = ValidationUtil.invalidPropertiesCount(submissionVM, isHomeNumberValid, isPhoneNumberValid, 'QuotePage', {}, isPhoneNumberLnPValid);
    const [errorTimestamp, setErrorTimestamp] = useState(Date.now());
    const [ismodalOpen, setIsModalOpen] = useState(true);
    const [isQuoteLoading, setIsQuoteLoading] = useState(false);
    const [isAddonsClicked, setIsAddonsClicked] = useState(false);
    const inValidPropLenghtNew = invalidPropsLength + unSelectedCount;
    const [visitedPage, setVisitedPage] = useState('quote');
    const tyaCoverages = setBrandSpecificCoverages(_.get(submissionVM.value, 'lobData.personalMotor.offerings[0].coverages.pmVehicleCoverages[0].coverages'));

    useEffect(() => {
        const loadPrevUpdatedCoverages = async () => {
            const loadedPrevUpdatedCoverages = loadArrayFromSessionStorage(`unchangedCoverages-${quoteID}`);
            if (loadedPrevUpdatedCoverages == null || loadedPrevUpdatedCoverages.length === 0) {
                const initialSubmissionVM = viewModelService.clone(submissionVM);
                const initialCoverages = getCoveragesArray(initialSubmissionVM);
                window.sessionStorage.setItem(`unchangedCoverages-${quoteID}`, JSON.stringify(initialCoverages));
            }
        };
        loadPrevUpdatedCoverages();
        window.sessionStorage.setItem("lastVisitedStepIndex", JSON.stringify(4));
    }, []);

    const navigateToDetails = useCallback((pathname) => {
        const indexOfPage = _.findIndex(
            steps,
            ({ path }) => path === pathname
        );
        if (submissionVM.bindData.autoRenew_itb.value === undefined) {
            submissionVM.bindData.autoRenew_itb.value = true;
        }
        updateWizardData(submissionVM);
        jumpTo(indexOfPage);
    }, [jumpTo, steps, submissionVM, updateWizardData]);

    const handleHomeNumChange = useCallback(
        (value, path) => {
            const anyDigitPattern = /^\d*$/;
            const betweenRangePattern = /^\d{4,11}$/;
            if (anyDigitPattern.test(value)) {
                if (betweenRangePattern.test(value) || value === '') {
                    setIsHomeNumberValid(true);
                } else {
                    setIsHomeNumberValid(false);
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, 'baseData.accountHolder.homeNumber', value);
                updateWizardData(newSubmissionVM);
            }
        },
        [viewModelService, submissionVM, updateWizardData]
    );

    const handleMobileNumChange = useCallback(
        (value, path) => {
            const pattern = /^[0-9]{0,11}$/;
            const allowTestNums = /^(07700900[0-9]{3})|(07[0-9]{9})$/;
            if (pattern.test(value)) {
                if (allowTestNums.test(value)) {
                    setIsPhoneNumberValid(true);
                } else {
                    setIsPhoneNumberValid(false);
                }
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM.value, 'baseData.accountHolder.cellNumber', value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleEmailChange = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, 'baseData.accountHolder.emailAddress1', value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const handleUnselectedCountChange = (newCount) => {
        setUnSelectedCount(newCount);
    };

    const checkCoveragesChanged = () => {
        let result = false;
        let currentCoverages = getCoveragesArray(submissionVM);
        let prevCoverages = loadArrayFromSessionStorage(`unchangedCoverages-${quoteID}`);
        if(prevCoverages == null || prevCoverages.length === 0) return result;
        currentCoverages.forEach((cov) => {
            if(result === false) {
                let prevCov = prevCoverages.find((pCov) => pCov.CoverageCode === cov.CoverageCode);
                if (cov.SelectedTerm !== prevCov.SelectedTerm) {
                    result = true;
                }
            }
        });
        return result;
    };

    const handleCoveragesRadioButtonSelected = () => {
        setIsAddonsClicked(checkCoveragesChanged());
    };

    const handleSelectedPlanBln = (value) => {
        setIsAddonsClicked(false);
        setIsQuoteLoading(value);
    };

    const updateCoverageSelectionPromise = useCallback((selected, patternCode, submission, quotePriceUpdate, prevSelected) => {
        return new Promise((resolve, reject) => {
            resolve(CoverageUtil.updateCoverageSelection(selected, patternCode, submission, quotePriceUpdate, prevSelected));
        });
    }, []);
    const updateCoverageTermSelectionPromise = useCallback((chosenTerm, covPatternCode, covTermPatternCode, submission, quotePriceUpdate, prevSelectedTerm) => {
        return new Promise((resolve, reject) => {
            resolve(CoverageUtil.updateCoverageTermSelection(chosenTerm, covPatternCode, covTermPatternCode, submission, quotePriceUpdate, prevSelectedTerm));
        });
    }, []);
    const updateOfferedQuotes = useCallback((submission) => {
        submissionVM.quoteData.offeredQuotes = submission.quoteData.offeredQuotes;
    }, [submissionVM]);
    const updateCoverageSelection = useCallback((selected, patternCode) => {
        let prevSelected = false;
        const offeredQuotes = _.get(submissionVM, 'quoteData.offeredQuotes');
        offeredQuotes.forEach((quote) => {
            const cov = getCoverage(patternCode, quote);
            prevSelected = cov.selected;
            cov.selected = selected;
        });
        setUpdateInFlight(true);
        return updateCoverageSelectionPromise(selected, patternCode, submissionVM, true, prevSelected)
            .then((qdd) => {
                return updateOfferedQuotes(qdd.value);
            })
            .finally(() => {
                setUpdateInFlight(false);
            });
    }, [submissionVM, updateCoverageSelectionPromise, updateOfferedQuotes]);
    const updateCoverageTermSelection = useCallback((chosenTerm, covPatternCode, covTermPatternCode) => {
        let prevSelectedTerm = null;
        const offeredQuotes = _.get(submissionVM, 'quoteData.offeredQuotes');
        offeredQuotes.forEach((quote) => {
            const coverage = getCoverage(covPatternCode, quote);
            coverage.selected = chosenTerm !== null;
            const covTerm = getCoverageTermFromCoverage(coverage, covTermPatternCode);
            prevSelectedTerm = covTerm.chosenTerm;
            covTerm.chosenTerm = chosenTerm;
            covTerm.updated = true; // Required to trigger update in PC
        });
        setUpdateInFlight(true);
        return updateCoverageTermSelectionPromise(chosenTerm, covPatternCode, covTermPatternCode, submissionVM, true, prevSelectedTerm)
            .then((qdd) => {
                return updateOfferedQuotes(qdd.value);
            })
            .finally(() => {
                setUpdateInFlight(false);
            });
    }, [submissionVM, updateCoverageTermSelectionPromise, updateOfferedQuotes]);

    const updateQuote = useCallback(
        (quotePath, volExcess) => {
            try {
                const annualPremium = _.get(submissionVM, 'quoteData.value.offeredQuotes[0].premium.total.amount').toFixed(2);
                submissionVM.quoteData.value.offeredQuotes[0].premium.total.amount = parseFloat(annualPremium);
                const lobDataPath = 'lobData.personalMotor.offerings[0]';
                const customQuote = getCustomQuote(submissionVM, quotePath, volExcess);
                _.set(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdProtection', true);
                return CustomQuoteService.updateQuote(customQuote, authHeader).then(
                    (response) => {
                        setStaleQuoteBranchCode(undefined);
                        if (response.quoteData && response.quoteData.offeredQuotes) {
                            response.quoteData.offeredQuotes = response.quoteData.offeredQuotes.map((offeredQuote) => {
                                offeredQuote.lobs = {};
                                if (response.lobData) {
                                    Object.keys(response.lobData).forEach(key => {
                                        const lobdata = response.lobData[key];
                                        if (lobdata.coverages) {
                                            offeredQuote.lobs[key] = lobdata.coverages;
                                        } else if (lobdata.offerings) {
                                            const offering = lobdata.offerings.find((offer) => offer.branchName === offeredQuote.branchName);
                                            offeredQuote.lobs[key] = offering ? offering.coverages : null;
                                        }
                                    });
                                }
                                return offeredQuote;
                            });
                        }
                        const offering = _.get(response, quotePath);
                        _.set(submissionVM.value, quotePath, offering);
                        const updatedCoverages = _.get(response, lobDataPath);
                        _.set(submissionVM.value, lobDataPath, updatedCoverages);
                        _.set(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0].voluntaryExcess', response.lobData.personalMotor.coverables.pmVehicles[0].voluntaryExcess);
                        _.set(submissionVM, 'bindData.paymentPlans', response.bindData.paymentPlans);
                        updateWizardData(submissionVM);
                        setIsQuoteLoading(false);
                        const covArr = getCoveragesArray(submissionVM);
                        window.sessionStorage.setItem(`unchangedCoverages-${quoteID}`, JSON.stringify(covArr));
                        window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
                        return response;
                    }
                );
            } catch (error) {
                history.push({
                    pathname: '/error',
                    data: error,
                    origin: "QuotePage [updateQuote]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
            }
        },
        [submissionVM, CustomQuoteService, authHeader, updateWizardData, quoteID, history]
    );

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            try {
                const lobName = ClausesUtil.getLobNameFromPath(lobPath);
                const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);
                return CustomQuoteService.updateCustomQuoteCoverages(customQuote, authHeader).then(
                    (response) => {
                        const updatedClauses = _.get(response, `coverages.${lobName}`);
                        const newSubmissionVM = viewModelService.clone(submissionVM);
                        // Update the offering status to stale
                        setStaleQuoteBranchCode(response.quote.branchCode);
                        // Update local offering with new one from xcenter
                        _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                        // Update local quote status with new one from xcenter
                        const status = _.get(response, 'quote.status', 'Draft');
                        _.set(submissionVM.value, `${quotePath}.status`, status);
                        // Update local errorsAndWarnings with new one from xcenter
                        _.set(submissionVM.value, 'errorsAndWarnings', response.errorsAndWarnings);
                        // Update premium with new one from xcenter
                        _.set(submissionVM.value, `${quotePath}.premium`, response.quote.premium);
                        const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                            submissionVM,
                            newSubmissionVM,
                            `${lobPath}.coverages.pmLineCoverages`
                        );
                        const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                            submissionVM,
                            newSubmissionVM,
                            `${lobPath}.coverages.pmVehicleCoverages`
                        );
                        const allRemovedFields = [
                            ...removedFieldsFromBaseCoverages,
                            ...removedFieldsFromAdditionalCoverages
                        ];
                        disregardFieldValidation(allRemovedFields);
                        updateWizardData(submissionVM);
                    }
                );
            } catch (error) {
                // re-throw this error within the updater function
                // it will be triggered during state update
                history.push({ 
                    pathname: '/error',
                    data: error,
                    origin: "QuotePage [onClauseChange]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            }
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            viewModelService,
            disregardFieldValidation,
            updateWizardData
        ]
    );

    const setNextStepsBasedOnPaymentMethod = useCallback(() => {
        let updatedSteps = [];
        if (submissionVM.value.isAnnualPaymentPlan_AND()) {
            allSteps.forEach((step) => {
                if (ANNUAL_PAYMENT_LEGAL_PAGES.find((page) => page === step.path)) {
                    updatedSteps.push(step);
                }
            });
            changeNextSteps(updatedSteps);
        } else {
            allSteps.forEach((step) => {
                if (MONTHLY_PAYMENT_LEGAL_PAGES.find((page) => page === step.path)) {
                    updatedSteps.push(step);
                }
            });
            changeNextSteps(updatedSteps);
        }
    }, [allSteps, changeNextSteps, submissionVM.value]);

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            try {
                const lobName = ClausesUtil.getLobNameFromPath(lobPath);
                const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

                return CustomQuoteService.forceUpdateCustomQuoteCoverages(customQuote, authHeader).then(
                    (response) => {
                        const updatedClauses = _.get(response, `coverages.${lobName}`);
                        // Update local offering with new one from xcenter
                        _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                        // Update local quote status with new one from xcenter
                        const status = _.get(response, 'quote.status', 'Draft');
                        _.set(submissionVM, `${quotePath}.status`, status);
                        // Update local errorsAndWarnings with new one from xcenter
                        _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                        // Update premium with new one from xcenter
                        _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                        updateWizardData(submissionVM);
                    }
                );
            } catch (error) {
                // re-throw this error within the updater function
                // it will be triggered during state update
                history.push({ 
                    pathname: '/error',
                    data: error,
                    origin: "QuotePage [resetQuote]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            }
        },
        [initialSubmissionVM, submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            try {
                const lobName = ClausesUtil.getLobNameFromPath(lobPath);
                const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

                return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                    (response) => {
                        setStaleQuoteBranchCode(undefined);
                        const updatedClauses = _.get(response, `coverages.${lobName}`);
                        // Update local offering with new one from xcenter
                        _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                        const status = _.get(response, 'quote.status', 'Draft');
                        _.set(submissionVM, `${quotePath}.status`, status);
                        // Update local errorsAndWarnings with new one from xcenter
                        _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                        // Update premium with new one from xcenter
                        _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                        updateWizardData(submissionVM);
                        return response;
                    }
                );
            } catch (error) {
                // re-throw this error within the updater function
                // it will be triggered during state update
                history.push({ 
                    pathname: '/error',
                    data: error,
                    origin: "QuotePage [recalculate]",
                    quoteID: _.get(submissionVM.value, 'quoteID') || ''
                });
                return false;
            }
        },
        [submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );
    const setStepsBasedOnQuotePageVisit = useCallback(() => {
        let updatedSteps = [];
        const QUOTE_PAGE_PATH = 'quote';
        allSteps.forEach((step) => {
            if (step.path === QUOTE_PAGE_PATH) {
                updatedSteps.push(step);
                setVisitedPage(step.path);
            }
        });
    }, [allSteps]);

    useEffect(() => {
        const chosenQuote = _.get(submissionVM, 'bindData.chosenQuote.value');
        allSteps.forEach((step) => {
            if (step.path === 'quote' && chosenQuote !== undefined) {
                setUnSelectedCount(0);
            }
        });
    }, []);

    const onNext = useCallback(async () => {
        let shouldUpdateQuote = false;
        setNextStepsBasedOnPaymentMethod();
        setStepsBasedOnQuotePageVisit();
        if (((inValidPropLenghtNew) > 0 && !currentStep.stepProps.showSelectedAddons)) {
            ScrollToElementUtil.scrollToElement('quoteSectionContainerInner');
            setErrorTimestamp(Date.now());
            setSubmitted(true);
            return false;
        }
        const ncdProtectionAllowed = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdProtectionAllowed');
        const ncdProtection = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdProtection');
        if (ncdProtectionAllowed && !ncdProtection) {
            _.set(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.value[0].ncdProtection', true);
            shouldUpdateQuote = true;
        }
        if ((shouldUpdateQuote || isAddonsClicked) && !isQuoteLoading) {
            await updateQuote(
                'quoteData.offeredQuotes[0]',
                submissionVM.lobData.personalMotor.coverables.pmVehicles.value[0].voluntaryExcess
            );
        }
        try {
            window.sessionStorage.setItem("submissionVm", JSON.stringify(submissionVM.value));
            const newUpdatedSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newUpdatedSubmissionVM);
            return newUpdatedSubmissionVM;
        } catch (error) {
            history.push({
                pathname: '/error',
                data: error,
                origin: "QuotePage [onNext]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [setNextStepsBasedOnPaymentMethod, setStepsBasedOnQuotePageVisit, inValidPropLenghtNew, currentStep, isAddonsClicked, isQuoteLoading, updateQuote, submissionVM, viewModelService, updateWizardData, history]);

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const MakeScrollCallback = (elementId) => useCallback(() => {
        ScrollToElementUtil.scrollToElementWithOffset(elementId, 'quotePriceBox');
    }, [elementId]);
    const onLegalExpensesCoverClick = MakeScrollCallback('legalExpensesCoverElement');
    const onEnhancedCourtesyCarCoverClick = MakeScrollCallback('enhancedCourtesyCarCoverElement');
    const onDrivingAbroadCoverClick = MakeScrollCallback('drivingAbroadCoverElement');
    const onTrailerCoverClick = MakeScrollCallback('trailerCoverElement');
    const onBreakDownCoverClick = MakeScrollCallback('coverageNameHeaderTMBreakdownCov');

    const generateOverrides = useCallback((isSubmitted) => {
        const columnData = generateColumnData(submissionVM);
        const { quoteID } = submissionVM;
        const { underwritingIssues } = props;
        const setUpdateFunctionsForCoverages = () => {
            const overrides = tyaCoverages.flatMap((cov, index) => {
                return {
                    [`coverageSection${index}`]: {
                        chosenQuote: _.get(submissionVM, 'bindData.chosenQuote.value'),
                        allSteps: allSteps,
                        onUnSelectedCountChange: handleUnselectedCountChange,
                        onCoveragesRadioButtonSelected: handleCoveragesRadioButtonSelected,
                        submitted: submitted,
                        updateCoverageSelection: updateCoverageSelection,
                        updateCoverageTermSelection: updateCoverageTermSelection,
                        showSelectedAddon: currentStep.stepProps.showSelectedAddons
                    }
                };
            });
            return Object.assign({}, ...overrides);
        };
    
        return {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
            },
            inlineNotificationErrorBarQuotePage: {
                visible: ((inValidPropLenghtNew) > 0 && submitted),
            },
            quoteTable: {
                columnData: columnData,
                tableData: generateTableData(submissionVM, columnData, translator),
                underwritingIssues: underwritingIssues,
                quoteID: quoteID.value
            },
            startDate: {
                visible: showQuoteStartDate
            },
            print: {
                visible: !showQuoteStartDate
            },
            yourQuoteHeaderTitle: {
                className: styles.andYourQuoteHeaderTitle,
            },
            yourQuoteHeaderSubtitle2: {
                className: styles.andYourQuoteHeaderSubtitle2,
            },
            quotePriceAmount: {
                visible: !isQuoteLoading,
                className: styles.andAnnualPremiumAmount,
                value: {
                    amount: _.get(submissionVM, 'quoteData.value.offeredQuotes[0].premium.total.amount'),
                    currency: 'GBP'
                }
            },
            quotePriceLoader: {
                visible: isQuoteLoading,
            },
            quotePriceContainer: {
                className: styles.andQuoteCellOffering,
            },
            quotePriceBox: {
                className: classNames(styles.andQuoteRow,
                    styles.andQuoteOfferingHeader,
                    styles.quotePriceBox,
                    styles.quoteTableHeader
                ),
            },
            quotePriceHeader: {
                className: styles.andAnnualPremiumTitle,
            },
            excessSection: {
                data: submissionVM,
                onUpdateQuote: updateQuote,
                onHandleSelectedVoluntaryBln: handleSelectedPlanBln
            },
            PMQuoteReviewComponent: {
                data: submissionVM,
                navigateToDetails: navigateToDetails,
                onBreakDownCoverClick: onBreakDownCoverClick,
                onTrailerCoverClick: onTrailerCoverClick,
                onDrivingAbroadCoverClick: onDrivingAbroadCoverClick,
                onEnhancedCourtesyCarCoverClick: onEnhancedCourtesyCarCoverClick,
                onLegalExpensesCoverClick: onLegalExpensesCoverClick,
            },
            pncdSection: {
                data: submissionVM,
            },
            PMContactDetailsComponent: {
                data: submissionVM,
                submitted: isSubmitted,
                handlePhoneNumChange: handleMobileNumChange,
                handleLandlineNumChange: handleHomeNumChange,
                handleEmailChange: handleEmailChange
            },
            PMPaymentPlansComponent: {
                data: submissionVM,
                updateWizardData: updateWizardData,
                onUpdatePaymentPlan: updateQuote,
                onHandleSelectedPlanBln: handleSelectedPlanBln,
                isAddonsClicked: isAddonsClicked,
                submitted: submitted,
            },
            coveragesContainer: {
                data: tyaCoverages
            },
            AmountContainer: {
                className: isQuoteLoading && styles.quoteLoader,
            },
            quotePriceFrequency: {
                className: isQuoteLoading && styles.quotePriceFrequency,
            },
            PrivacyNoticeDocLink: {
                href: DocUtil.getFileUrl('tya','PRIVACY_NOTICE')
            },
            ...setUpdateFunctionsForCoverages()
        };
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, translator, submitted, isQuoteLoading, isAddonsClicked, inValidPropLenghtNew]);

    useEffect(() => {
        setIsModalOpen(!getCookie('consent'));
        const offerings = _.get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = _.filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = _.map(draftOffers, 'branchCode');
        setStaleQuoteBranchCode(draftBranchCodes);
        clearVisitedStepsAfterCurrent();
        // Store the initial SubmissionVM when component is mounted
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));
        if (submissionVM.bindData.autoRenew_itb.value === undefined) {
            _.set(submissionVM, 'bindData.autoRenew_itb.value', true);
        }

        // inject mock data to prevent wizardData.subtreeValid is invalid
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            PATH_TO_MOCK_DATA,
            ...MOCK_DATA_TO_SET
        );

        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "QuotePage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    const handleModalActivity = () => {
        setIsModalOpen(!ismodalOpen);
    };

    const getCookie = (name) => {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return false;
    };

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage onNext={onNext} showNext={true} disableNext={isQuoteLoading ? true : false} disablePrevious={isQuoteLoading ? true : false} showPrevious={true} nextLabel={'Continue'} 
                previousLabel={'Back'} disableCancel={true} cancelLabel={''}>
                {({ onNext }) => {
                    const resolvers = {
                        resolveClassNameMap: styles,
                        resolveCallbackMap: {
                            onStaleQuoteBranchCode: onStaleQuoteBranchCode,
                            onRecalculate: recalculate,
                            onResetQuote: resetQuote,
                            onChangeSubmissionAndSync: changeSubmissionAndSync,
                            onChangeSubmission: changeSubmission,
                            onSyncCoverages: syncCoverages,
                            onPrint: handlePrint
                        }
                    };

                return (
                    <div>
                        { isAggsQuote ?
                            <div>
                                <CookiesModal
                                    ismodalOpen={ismodalOpen}
                                    handleModalActivity={handleModalActivity}
                                    getCookie={getCookie}
                                />
                            </div>
                            :
                            null
                        }
                        
                        <ViewModelForm
                            uiProps={metadata.pageContent}
                            model={submissionVM}
                            overrideProps={generateOverrides(submitted)}
                            onModelChange={updateWizardData}
                            classNameMap={resolvers.resolveClassNameMap}
                            callbackMap={resolvers.resolveCallbackMap}
                            onValidationChange={onValidate}
                            showErrors={submitted}
                        />
                        <ScrollToError counter={errorTimestamp} timeout={200} />
                    </div>
                );
            }}
            </WizardPage>
        </ErrorBoundary>
    );
}

QuotePage.propTypes = wizardProps;
export default withRouter(QuotePage);
