import { defineMessages } from 'react-intl';

export default defineMessages({
    pncdTitle: {
        id: 'quoteandbind.pm.components.pncd.Title',
        defaultMessage: 'No Claim Discount Protection',
    },
    pncdAddon_1: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.1',
        defaultMessage: 'No Claim Discount protection allows you to make one or more claims before your number of No Claim Discount years falls. Please see the ',
    },
    pncdAddon_2: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.2',
        defaultMessage: ' for details. This can also be found in Section 12 of the Private Car Policy Document.',
    },
    pncdAddon_3: {
        id: 'quoteandbind.pm.components.pncd.PNCD Addon.3',
        defaultMessage: 'No Claim Discount protection does not protect the overall price of your insurance policy. The price of your insurance policy may increase following an accident even if you were not at fault.',
    },
    pncdStepBack: {
        id: 'quoteandbind.pm.components.pncd.PNCD step back',
        defaultMessage: 'step-back procedure',
    },
    pncdIncluded: {
        id: 'quoteandbind.pm.components.pncd.PNCD included',
        defaultMessage: 'Good news! No Claim Discount Protection is included at no additional cost with this policy.',
    }
});
