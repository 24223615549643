import { defineMessages } from 'react-intl';

export default defineMessages({
    VRNMissing: {
        id: 'quoteandbind.views.VRN-Missing.VRN-Missing',
        defaultMessage: 'VRN-Missing',
    },
    VRNMissingWarning: {
        id: 'quoteandbind.views.VRN-Missing.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    VRNMissingSolution: {
        id: 'quoteandbind.views.VRN-Missing.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.VRN-Missing.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.VRN-Missing.Reference Number',
        defaultMessage: 'Reference Number',
    },
});
