/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import metadata from './PMPaymentPage.metadata.json5';
import './PMPaymentPage.messages';

function PMPaymentPage(props) {
    const { wizardData: submissionVM, updateWizardData, goNext: wizardGoNext, history } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isReadyToPay, setReadyToPayState] = useState(false);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isPaymentSuccess, setPaymentSuccessState] = useState(false);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PMPaymentPage');
    const [submissionBound, setSubmissionBound] = useState(false);
    const [gatewayURL, setGatewayURL] = useState('');

    const bindSubmission = useCallback(async () => {
        if (submissionBound) {
            return;
        }
        try {
            let submissionResponse = await LoadSaveService.bindSubmission(submissionVM.value);
            updateWizardData(submissionResponse);
            window.sessionStorage.clear();
            wizardGoNext();
            setSubmissionBound(true);
        } catch (error) {
            // re-throw this error within the updater function
            // it will be triggered during state update
            history.push({
                pathname: '/error',
                data: error,
                origin: "PMPaymentPage [bindSubmission]",
                quoteID: _.get(submissionVM.value, 'quoteID') || ''
            });
            return false;
        }
    }, [LoadSaveService, history, submissionBound, submissionVM.value, updateWizardData, wizardGoNext]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!isReadyToPay) {
            _.set(submissionVM, 'bindData.applicationType.value', 'SFE_QNB_TYA');
            Promise.all([
                LoadSaveService.preBindSubmission(submissionVM.value),
            ])
                .then(([submissionResponse]) => {
                    let paymentURL = submissionResponse.bindData.sagePayURL_itb;
                    if (paymentURL == null) {
                        paymentURL = 'http://localhost/quote-and-buy/common/html/carddetailsredirectportals.html';
                    }
                    setGatewayURL(paymentURL);
                    setReadyToPayState(true);
                })
                .catch((error) => {
                    history.push({
                        pathname: '/error',
                        data: error,
                        origin: "PMPaymentPage [preBindSubmission]",
                        quoteID: _.get(submissionVM.value, 'quoteID') || ''
                    });
                });
        }
        window.addEventListener(
            'message',
            (event) => {
                try {
                    if (event.data.message === 'success') {
                        bindSubmission();
                    }
                    if (event.data.message === 'cancelled' || event.data.message === 'failure') {
                        history.push('/purchase-failed');
                    }
                } catch (error) {
                //
                }
            },
            false,
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        return submissionVM;
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        paymentInfoHeader: {
            visible: _.get(submissionVM.value, 'bindData.selectedPaymentPlan.name') === 'Annual Payment Plan'
        },
        paymentInfoHeader2: {
            visible: _.get(submissionVM.value, 'bindData.selectedPaymentPlan.name') === 'Installment New Business Payment'
        },
    };

    const handleError = useCallback((error = {}) => {
        history.push({
            pathname: '/error',
            data: error,
            origin: "PMPaymentPage",
            quoteID: _.get(submissionVM.value, 'quoteID') || ''
        });
    }, [history]);

    return (
        <ErrorBoundary onError={handleError}>
            <WizardPage
                showNext={false}
                showCancel={false}
                showPrevious={false}
            >
                {({ onNext }) => {
                    const resolvers = {
                        resolveCallbackMap: {
                            PaymentSuccess_AND: () => bindSubmission().then(onNext)
                        }
                    };
                    return (
                        <div>
                            <ViewModelForm
                                uiProps={metadata.pageContent}
                                model={submissionVM}
                                overrideProps={overrideProps}
                                onModelChange={updateWizardData}
                                callbackMap={resolvers.resolveCallbackMap}
                            />
                            <iframe
                                class="gw-payment-iframe"
                                scrolling="no"
                                src={gatewayURL}
                            />
                        </div>
                    );
                }}
            </WizardPage>
        </ErrorBoundary>
    );
}

PMPaymentPage.propTypes = wizardProps;
export default withRouter(PMPaymentPage);
